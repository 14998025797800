import React from 'react'

function ShampooIcon({
    className = "h-12 sm:h-16 w-12 sm:w-16 stroke-black"
}) {
    return (
        <svg className={className} viewBox="0 0 48 80" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M13 18.4H31M13 18.4V27.4H31V18.4M13 18.4V13C13 7.6 18.4 4 23.8 4H43.6C43.6 4 31 7.6 31 14.8V18.4M4 31C4 30.0452 4.37929 29.1295 5.05442 28.4544C5.72955 27.7793 6.64522 27.4 7.6 27.4H36.4C37.3548 27.4 38.2705 27.7793 38.9456 28.4544C39.6207 29.1295 40 30.0452 40 31V65.2C40 65.2 40 76 29.2 76H14.8C4 76 4 65.2 4 65.2V31Z" stroke-width="7.2" stroke-linecap="round" stroke-linejoin="round" />
        </svg>
    )
}

export default ShampooIcon