import React from 'react'
import { useDispatch } from 'react-redux'

// Icons
import SearchIcon from 'assets/icons/general/SearchIcon'

// Cookies
import Cookies from "js-cookie";

// Apis
import useFetch from 'utils/hooks/useFetch';
import {
    LOGOUT_USER
} from 'api';

function Menu({
    isOpen,
    setMenuOpen,
    setOpenLoginUser,
    userDetail
}) {
    const dispatch = useDispatch();

    const { data: logoutData, loading: loadingLogout, fetch: logoutUser } = useFetch(LOGOUT_USER)

    const handleOnClickSignIn = () => {
        setMenuOpen(false)
        setOpenLoginUser(true)
    }

    const handleOnClickSignOut = async () => {
        try {
            const result = await logoutUser();
            if (
                result &&
                result &&
                result.message &&
                result.message === "User logged out"
            ) {
                setMenuOpen(false);
                Cookies.remove("ut");
                dispatch({ type: "SET_USER_DETAIL", userDetail: {} });
                window.location.assign("/");
            }
        } catch (err) {
            console.log(err.response);
        }
    };

    return (
        <div className={`md:hidden fixed top-0 bottom-0 left-0 right-0 bg-white/50 backdrop-blur-lg ${isOpen ? "inline-block" : "hidden"} z-50`}>
            <div className='app-nav-container h-full flex flex-col gap-8'>
                <div className='text-right font-medium'>
                    <button className='text-sm active:border-b-2 active:border-black transition-all' onClick={() => setMenuOpen(false)}>Close</button>
                </div>
                <div className={`flex flex-col justify-between flex-1`}>
                    <ul className='flex flex-col gap-2 font-medium'>
                        <li>
                            <a href="/">Home</a>
                        </li>
                        <li>
                            <a href="/about">About</a>
                        </li>
                        <li>
                            <a href="/services">Our Services</a>
                        </li>
                        <li>
                            <a href="/contact-us">Contact Us</a>
                        </li>
                        <li>
                            <a href="/">Book Now</a>
                        </li>
                        {userDetail && userDetail.id && (
                            <li className='border-y border-black py-2'>
                                {userDetail && userDetail.username}
                            </li>
                        )}
                        {userDetail && userDetail.id ? (
                            <li className='cursor-pointer' onClick={() => handleOnClickSignOut(true)}>
                                Sign Out
                            </li>
                        ) : (
                            <li className='cursor-pointer' onClick={() => handleOnClickSignIn()}>
                                Sign In
                            </li>
                        )}
                    </ul>
                    <div className='flex items-center gap-2 border border-black rounded-md px-3 py-2'>
                        <input
                            className='flex-1 bg-transparent outline-none text-sm text-black placeholder-black/50'
                            placeholder='Search for anything'
                        />
                        <button>
                            <SearchIcon className='h-4 w-4 fill-black' />
                        </button>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default Menu