import React, { useEffect } from 'react'
import BookingComponent from 'components/booking/BookingComponent';
import useFetch from 'utils/hooks/useFetch';
import { GET_ALL_SERVICES } from 'api';

function Booking() {
    const { data: _services, loading: loadingServices, fetch: getAllServices } = useFetch(GET_ALL_SERVICES)
    const services = _services?.filter(service => service?.is_active)

    useEffect(() => {
        getAllServices()
    }, [getAllServices])

    return (
        <BookingComponent services={services} />
    )
}

export default Booking