import axios from 'axios'
import { API_BASE_URL } from 'utils/constants/api'

const baseURL = API_BASE_URL

const api = axios.create({
    baseURL,
    withCredentials: true
})

// GENERAL
export const GET_GENERAL_SETTINGS = {
    method: "get",
    url: "/api/booking/v1/settings"
}

export const GET_SITE_MANAGER_SETTINGS = {
    method: "get",
    url: "/api/booking/v1/site-manager/"
}

export const GET_FAQ_LIST = {
    method: "get",
    url: "/api/booking/v1/site-manager/faq/all"
}

export const GET_ALL_MPAYMENT_CHANNELS = {
    method: "get",
    url: "/api/booking/v1/mpaymentchannels/all"
}

export const GET_CURRENCIES = {
    method: "get",
    url: "/api/booking/v1/currencies"
}

// SERVICE
export const GET_ALL_SERVICES = {
    method: 'get',
    url: "/api/booking/v1/services/all"
}

export const GET_SERVICE_DETAILS = {
    method: 'get',
    url: "/api/booking/v1/services/id/:service_id"
}

// BOOKING
export const CREATE_BOOKING = {
    method: "post",
    url: "/api/booking/v1/bookings/create"
}

// TRANSACTION
export const CREATE_TRANSACTION = {
    method: "post",
    url: "/api/booking/v1/transactions/create"
}

export const GET_ORDER_BY_ORDER_ID = {
    method: "GET",
    url: "/api/booking/v1/transactions/order/id/:order_id"
}

export const GET_PRICE_CALCULATION = {
    method: "post",
    url: "/api/booking/v1/services/calculate"
}

// USER
export const REGISTER_USER = {
    method: "post",
    url: "/api/sso/v1/users/create"
};

export const VERIFY_USER_ACCOUNT = {
    method: "post",
    url: "/api/sso/v1/users/verify/:token"
};

export const LOGIN_USER = {
    method: "post",
    url: "/api/sso/v1/users/login"
};

export const LOGOUT_USER = {
    method: "post",
    url: "/api/sso/v1/users/logout"
};

export const REQUEST_FORGOT_PASSWORD = {
    method: "get",
    url: "/api/sso/v1/users/request/reset/password/:email"
};

export const RESET_PASSWORD_USER = {
    method: "post",
    url: "/api/sso/v1/users/change/password"
};

export const GET_USER_PROFILE = {
    method: "get",
    url: "/api/sso/v1/users/profile"
};

export const CREATE_USER_PROFILE = {
    method: "post",
    url: "/api/sso/v1/users/profile/create"
};

export const GET_GUEST_TOKEN = {
    method: "get",
    url: "/api/sso/v1/users/token/guest"
}

export const SUBSCRIBE_NEWSLETTER = {
    method: "post",
    url: "/api/sso/v1/users/newsletter/on"
}

export const SEND_CONTACT_FORM = {
    method: "post",
    url: "/api/booking/v1/emails/contact-us"
}

export default api;