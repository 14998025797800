import { ConfirmationModule } from 'modules/booking-module-client';
import React from 'react'
import { Navigate, useNavigate } from 'react-router-dom';

function PaymentSuccess() {
    const navigate = useNavigate();
    const urlParams = new URLSearchParams(window.location.search);
    const orderId = urlParams.get('order_id');
    const statusCode = urlParams.get('status_code');
    const transactionStatus = urlParams.get('transaction_status');

    if (!orderId || !transactionStatus) {
        return (
            <Navigate to="/" />
        )
    } else if (transactionStatus === 'pending') {
        return (
            <Navigate to={`/payment/pending?order_id=${orderId}&status_code=${statusCode}&transaction_status=${transactionStatus}`} />
        )
    }
    return (
        <div className='app-main-container max-w-xl mx-auto flex-1 flex items-center'>
            <ConfirmationModule
                type="success"
                bookingConfirmation={{
                    number: orderId,
                    description: "Thank you for choosing us. We look forward to serving you and providing a memorable experience."
                }}
            />
        </div>
    )
}

export default PaymentSuccess