import React, { useEffect, useState } from 'react'
import { useNavigate, useParams } from 'react-router-dom'
import { useFormik } from 'formik';

import ArrowRightIcon from 'assets/icons/general/ArrowRightIcon';

import BookingModule from 'modules/booking-module-client';

import {
  bookingConfirmation,
  preferredTimeOptions,
  titleOptions,
} from 'utils/constants/data'
import {
  page1InitialValues,
  page2InitialValues,
  page3InitialValues,
  page1ValidationSchema,
  page2ValidationSchema,
  page3ValidationSchema,
  countryPhoneCodes,
  guestInitialValues,
} from 'utils/constants/index';
import useFetch from 'utils/hooks/useFetch';
import {
  CREATE_BOOKING,
  CREATE_TRANSACTION,
  GET_ALL_MPAYMENT_CHANNELS,
  GET_SERVICE_DETAILS,
  GET_PRICE_CALCULATION
} from 'api';
import moment from 'moment';

function BookingComponent({
  services = [],
}) {
  const { id } = useParams();
  const navigate = useNavigate();

  const { data: service, fetch: getService } = useFetch(GET_SERVICE_DETAILS)
  const { fetch: createBooking } = useFetch(CREATE_BOOKING)
  const { fetch: createTransaction } = useFetch(CREATE_TRANSACTION)
  const { data: mPaymentChannels, fetch: getAllMPaymentChannels } = useFetch(GET_ALL_MPAYMENT_CHANNELS)
  const { data: priceCalculation, error: priceCalculationError, fetch: getPriceCalculation } = useFetch(GET_PRICE_CALCULATION)

  const [page, setPage] = useState(1)
  const [initialValues, setInitialValues] = useState(page1InitialValues)
  const [validationSchema, setValidationSchema] = useState(page1ValidationSchema)
  const [isPrivacyPolicyAgreementChecked, setIsPrivacyPolicyAgreementChecked] = useState(false)
  
  const {
    values,
    errors,
    touched,
    handleChange,
    handleBlur,
    handleSubmit,
    isSubmitting,
    getFieldProps,
    setValues,
  } = useFormik({
    enableReinitialize: true,
    initialValues,
    validationSchema,
    onSubmit: async (values, { setSubmitting }) => {
      setSubmitting(true)
      if (page === 3) {
        const newBooking = await createBooking({
          data: {
            email: values?.email,
            mpayment_channel_id: values?.payment_method,
            booking: {
              service_package_id: parseInt(values.duration),
              booking_time_id: values.time
            },
            guests: values?.guests?.map(guest => ({
              profile_id: null,
              first_name: guest.first_name,
              last_name: guest.last_name,
              salutation: guest.title,
              date_of_birth: guest.date_of_birth,
              gender: guest.gender,
              country_phone_code: guest.country_phone_code,
              phone_number: guest.phone_number,
              address: guest.address,
              extra_note: guest.notes,
              is_whatsapp_available: guest.whatsapp
            }))
          }
        })
        if (newBooking && newBooking.m_redirect_url) {
          window.location.assign(newBooking.m_redirect_url)
        }
        window.scrollTo({ top: 0 });
      } else if (page === 2) {
        setPage(3)
        setInitialValues({ ...page3InitialValues, ...values })
        setValidationSchema(page3ValidationSchema)
        window.scrollTo({ top: 0 });
      } else {
        setPage(2)
        const _page2InitialValues = { ...page2InitialValues, guests: Array.from({ length: values.num_guests }).map(() => guestInitialValues) }
        setInitialValues({ ..._page2InitialValues, ...values })
        setValidationSchema(page2ValidationSchema)
        window.scrollTo({ top: 0 });
      }
      setSubmitting(false)
    },
  })

  const packages = service?.packages?.filter(item => item?.is_active) || []
  const durationOptions = packages
    ? packages?.map(item => ({ ...item, value: parseInt(item.id), text: item.title.split(" - ")[1] || item.title.split(" - ")[0], price: item.price }))
    : []

  const details = {
    label: "Experience type",
    title: service?.title,
    amount: durationOptions?.find(item => parseInt(item.id) === parseInt(values?.duration))?.price || 0,
    quota: durationOptions?.find(item => parseInt(item.id) === parseInt(values?.duration))?.quota || 0,
    schedule_option: durationOptions?.find(item => parseInt(item.id) === parseInt(values?.duration))?.schedule_option || '',
    schedule_window: durationOptions?.find(item => parseInt(item.id) === parseInt(values?.duration))?.schedule_window || '',
  }

  const handleBack = () => {
    setIsPrivacyPolicyAgreementChecked(false)
    if (page === 1 || page === 4) {
      navigate(-1)
    } else {
      if (page === 3) {
        setPage(2)
        const _page2InitialValues = { ...page2InitialValues, guests: Array.from({ length: values.num_guests }).map(() => guestInitialValues) }
        setInitialValues({ ..._page2InitialValues, ...values })
        setValidationSchema(page2ValidationSchema)
        window.scrollTo({ top: 0 });
      } else if (page === 2) {
        setPage(1)
        setInitialValues(page1InitialValues)
        setValidationSchema(page1ValidationSchema)
        window.scrollTo({ top: 0 });
      }
    }
  }
  
  const slots = durationOptions?.find(item => parseInt(item.id) === parseInt(values?.duration))?.timeslots?.[0]?.[values?.preferred_time?.toLowerCase()]

  const availableSlots = slots?.map(item => {
    let availableTime = []
    slots.forEach((s) => {
      const sId = s.id
      const sDate = moment(s.date).format('YYYY-MM-DD')
      const itemDate = moment(item.date).format('YYYY-MM-DD')
      if (sDate == itemDate) {
        availableTime.push({
          id: sId,
          time: moment(s.date).format('HH:mm'),
          quota: s.quota
        })
      }
    })
    const slotObj = {
      date: moment(item.date)?.format("YYYY-MM-DD"),
      availableTime
    }
    return slotObj
  })
  
  const paymentChannelOptions = mPaymentChannels?.map((channel) => {
    const fee = channel?.is_percentage ? `${channel?.fee}%` : `Rp${new Intl.NumberFormat().format(channel?.fee)}`
    const description = `${fee} transaction fee applies`
    return ({
      ...channel,
      text: channel?.name,
      description
    })
  })

  useEffect(() => {
    if (id) {
      setValues((values) => ({
        ...values,
        service: id
      }), false)
    }
    getService({
      params: {
        service_id: id || values.service,
      }
    })
  }, [getService, id, values.service])

  useEffect(() => {
    if (
      values.duration &&
      values.payment_method &&
      values.num_guests &&
      values.num_guests > 0
    ) {
      const price = durationOptions?.find(item => parseInt(item.id) === parseInt(values?.duration))?.price || 0
      getPriceCalculation({
        data: {
          price,
          payment_channel_id: values.payment_method,
          qty: values.num_guests
        }
      })
    }
  }, [values.payment_method, values.num_guests, values.duration])

  useEffect(() => {
    getAllMPaymentChannels()
  }, [getAllMPaymentChannels])

  return (
    <div className='bg-app-cream flex-1'>
      <div className='app-main-container space-y-8'>
        <div className='app-animation-translate-start'>
          <button className='flex space-x-3 items-center' onClick={handleBack}>
            <div>
              <ArrowRightIcon className='h-3 w-3 sm:h-4 sm:w-4 fill-black rotate-180' />
            </div>
            <p className='text-sm sm:text-base'>Back to previous page</p>
          </button>
        </div>
        <div className='max-w-2xl mx-auto space-y-4 sm:space-y-6'>
          <p className='text-center font-serif text-xl sm:text-3xl font-bold mb-12 app-animation-translate-start'>{page === 4 ? "Booking confirmed" : "Book your spa experiences"}</p>
          <BookingModule
            values={values}
            errors={errors}
            touched={touched}
            handleChange={handleChange}
            handleBlur={handleBlur}
            handleSubmit={handleSubmit}
            isSubmitting={isSubmitting}
            page={page}
            bookingConfirmation={bookingConfirmation}
            details={details}
            availableSlots={availableSlots}
            getFieldProps={getFieldProps}
            preferredTimeOptions={preferredTimeOptions}
            titleOptions={titleOptions}
            durationOptions={durationOptions}
            countryPhoneCodes={countryPhoneCodes.map(code => ({ id: code.code, text: `${code.name}${code.code ? `(${code.code})` : ""}` }))}
            services={services?.map(service => ({ id: service.id, text: service.title }))}
            paymentChannelOptions={paymentChannelOptions}
            priceCalculation={priceCalculation}
            isPrivacyPolicyAgreementChecked={isPrivacyPolicyAgreementChecked}
            setIsPrivacyPolicyAgreementChecked={setIsPrivacyPolicyAgreementChecked}
          />
        </div>
      </div>
    </div>
  )
}

export default BookingComponent