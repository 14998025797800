import React from 'react'

function EmailIcon({
    className = "h-7 w-7 fill-black"
}) {
    return (
        <svg className={className} viewBox="0 0 34 18" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M30.4615 0H12.4615C10.9385 0 9.69231 1.24615 9.69231 2.76923V15.2308C9.69231 15.9652 9.98407 16.6696 10.5034 17.1889C11.0227 17.7082 11.7271 18 12.4615 18H30.4615C31.9985 18 33.2308 16.7677 33.2308 15.2308V2.76923C33.2308 2.03479 32.939 1.33042 32.4197 0.811089C31.9003 0.291757 31.196 0 30.4615 0ZM30.4615 15.2308H12.4615V5.08154L21.4615 9.69231L30.4615 5.08154V15.2308ZM21.4615 7.35231L12.4615 2.76923H30.4615L21.4615 7.35231ZM6.92308 15.2308C6.92308 15.4662 6.96462 15.6877 6.99231 15.9231H1.38462C0.620308 15.9231 0 15.3 0 14.5385C0 13.7769 0.620308 13.1538 1.38462 13.1538H6.92308V15.2308ZM4.15385 2.07692H6.99231C6.96462 2.31231 6.92308 2.53385 6.92308 2.76923V4.84615H4.15385C3.39231 4.84615 2.76923 4.22308 2.76923 3.46154C2.76923 2.7 3.39231 2.07692 4.15385 2.07692ZM1.38462 9C1.38462 8.23846 2.00769 7.61539 2.76923 7.61539H6.92308V10.3846H2.76923C2.00769 10.3846 1.38462 9.76154 1.38462 9Z" />
        </svg>
    )
}

export default EmailIcon