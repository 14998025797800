import React, { useState } from "react";
import { useDispatch } from "react-redux";

// React Google Recaptcha
import ReCAPTCHA from "react-google-recaptcha";

// Icons and Images
import EmailLogin from "assets/icons/user_authentication/EmailLogin.png";
import PasswordLogin from "assets/icons/user_authentication/PasswordLogin.png";
import EyePasswordHide from "assets/icons/user_authentication/EyePasswordHide.png";
import EyePasswordShow from "assets/icons/user_authentication/EyePasswordShow.png";
import CloseFullscreenBtn from "assets/icons/general/CloseFullscreenBtn.png";
import Loader from "assets/icons/general/Loader";

// Components
import { InputText } from "components/input/Inputs";

// Apis
import useFetch from "utils/hooks/useFetch";
import { LOGIN_USER } from "api";

// Helpers
import {
  validateEmail,
  validatePassword,
  timer
} from "utils/helpers";

const Login = ({
  setOpenLoginUser,
  setOpenRegisterUser,
  setOpenForgotPassword,
}) => {
  const dispatch = useDispatch();

  const { data: loginData, loading: loadingLogin, fetch: loginUser } = useFetch(LOGIN_USER)
  const [emailLogin, setEmailLogin] = useState("");
  const [passwordLogin, setPasswordLogin] = useState("");
  const [isCaptchaCorrect, setCaptchaCorrect] = useState(false);
  const [passwordInputType, setPasswordInputType] = useState("password");
  const [isEmptyEmail, setIsEmptyEmail] = useState(false);
  const [invalidEmailFormat, setInvalidEmailFormat] = useState(false);
  const [isEmptyPassword, setIsEmptyPassword] = useState(false);
  const [invalidPasswordMsg, setInvalidPasswordMsg] = useState("");
  const [isUserNotVerifiedYet, setIsUserNotVerifiedYet] = useState(false);
  const [isWrongEmailOrPassword, setIsWrongEmailOrPassword] = useState(false);
  const [isUserNotFound, setIsUserNotFound] = useState(false);

  const handleOnClickIconPassword = () => {
    if (passwordInputType === "password") {
      setPasswordInputType("text");
    } else {
      setPasswordInputType("password");
    }
  };

  const handleOnChangeCaptcha = (value) => {
    if (value) {
      setCaptchaCorrect(true);
    } else {
      setCaptchaCorrect(false);
    }
  };

  const handleLoginValidation = () => {
    let invalidEmail = false;
    let invalidEmailFormat = false;
    let invalidPassword = false;
    let invalidPassMsg = "";

    if (!emailLogin) {
      invalidEmail = true;
    } else {
      if (validateEmail(emailLogin) !== true) {
        invalidEmailFormat = true;
      }
    }
    if (!passwordLogin) {
      invalidPassword = true;
    } else {
      // validate password format
      const isValidPassword = validatePassword(passwordLogin);
      if (!isValidPassword) {
        invalidPassword = true;
        invalidPassMsg =
          "Password should be at least 8 characters long, contain at least one uppercase letter, one lowercase letter, and one number.";
      }
    }

    setIsEmptyEmail(invalidEmail);
    setInvalidEmailFormat(invalidEmailFormat)
    setIsEmptyPassword(invalidPassword);
    setInvalidPasswordMsg(invalidPassMsg);

    if (invalidEmail !== true && invalidPassword !== true) {
      return true;
    } else {
      return false;
    }
  };

  const handleOnClickLoginBtn = async () => {
    const data = {
      email: emailLogin,
      password: passwordLogin,
    };
    try {
      const isValid = handleLoginValidation();
      if (isValid) {
        const result = await loginUser({ data });
        if (result && result.id) {
          dispatch({
            type: "SET_USER_DETAIL",
            userDetail: result,
          });
          setOpenLoginUser(false)
          setOpenRegisterUser(false)
        }
      }
    } catch (err) {
      if (err && err.response && err.response.data) {
        const { response: { data: { message } = {} } = {} } = err || {};
        if (message) {
          if (message === "user-not-verified") {
            const beforeFunction = () => {
              return setIsUserNotVerifiedYet(true);
            };
            const afterFunction = () => {
              return setIsUserNotVerifiedYet(false);
            };
            timer(beforeFunction, afterFunction, 3000);
          }
          if (message === "wrong-email-or-password") {
            const beforeFunction = () => {
              return setIsWrongEmailOrPassword(true);
            };
            const afterFunction = () => {
              return setIsWrongEmailOrPassword(false);
            };
            timer(beforeFunction, afterFunction, 3000);
          }
          if (message === 'user-not-found') {
            const beforeFunction = () => {
              return setIsUserNotFound(true);
            };
            const afterFunction = () => {
              return setIsUserNotFound(false);
            };
            timer(beforeFunction, afterFunction, 3000);
          }
        }
      }
    }
  };

  const handleClickForgotPassword = () => {
    setOpenLoginUser(false)
    setOpenForgotPassword(true)
  }

  const handleClickRegisterHere = () => {
    setOpenLoginUser(false)
    setOpenRegisterUser(true)
  }


  return (
    <div className="flex justify-center items-center fixed text-left top-0 left-0 right-0 bottom-0 z-[999] bg-black/50">
      <div className="w-3/4 lg:w-2/5 py-8 lg:py-10 flex flex-col justify-center items-center bg-app-cream rounded-3xl shadow-2xl">
        <div
          className="w-full flex justify-end mr-20 mb-4 lg:mr-24"
          onClick={() => setOpenLoginUser(false)}
        >
          <img
            className="w-4 h-4 sm:w-5 sm:h-5 lg:w-7 lg:h-7 cursor-pointer"
            src={CloseFullscreenBtn}
            alt="close-fullscreen-btn"
          />
        </div>
        <div className="font-bold text-2xl md:text-4xl text-center">Welcome back</div>
        <div className="text-base md:text-base font-semibold mb-4 text-center">Sign in to your account</div>
        {isUserNotFound && (
          <div className="w-3/5">
            <div className="flex items-center justify-center text-red-500 bg-red-100 rounded-md p-3 mb-2">
              <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-info-circle" viewBox="0 0 16 16">
                <path d="M8 15A7 7 0 1 1 8 1a7 7 0 0 1 0 14m0 1A8 8 0 1 0 8 0a8 8 0 0 0 0 16" />
                <path d="m8.93 6.588-2.29.287-.082.38.45.083c.294.07.352.176.288.469l-.738 3.468c-.194.897.105 1.319.808 1.319.545 0 1.178-.252 1.465-.598l.088-.416c-.2.176-.492.246-.686.246-.275 0-.375-.193-.304-.533zM9 4.5a1 1 0 1 1-2 0 1 1 0 0 1 2 0" />
              </svg>
              <span className="ml-2">
                User not found
              </span>
            </div>
            <p>Seems like your email is not registered yet, please try again or sign up to register.</p>
          </div>
        )}
        <div className="w-3/5 mt-2">
          <div>
            <div className="flex items-center gap-2 mb-2">
              <img src={EmailLogin} alt="email-icon" />
              <div className="text-sm md:text-base">Email</div>
            </div>
            <InputText
              width={"100%"}
              fontSize={"16px"}
              border={"1px solid #000000"}
              borderRadius={"10px"}
              height={"5vh"}
              placeholder={"Fill in email address"}
              name={"loginEmailAddress"}
              value={emailLogin}
              setter={setEmailLogin}
            />
            {isEmptyEmail && !emailLogin ? (
              <div className="text-red-500 mt-2">This field is required</div>
            ) : null}
            {invalidEmailFormat ? (
              <div className="text-red-500 mt-2">Please input a valid email format</div>
            ) : null}
            {isUserNotVerifiedYet ? (
              <div className="text-red-500 mt-2">Please verify this account first</div>
            ) : null}
            {isWrongEmailOrPassword ? (
              <div className="text-red-500 mt-2">Wrong email or password</div>
            ) : null}
          </div>
        </div>
        <div className="w-3/5 mt-4">
          <div>
            <div className="flex items-center gap-2 mb-2">
              <img src={PasswordLogin} alt="password-icon" />
              <div className="text-sm md:text-base">Password</div>
            </div>
            <div className="flex relative items-center">
              <InputText
                className="text-sm md:text-base"
                type={passwordInputType}
                width={"100%"}
                border={"1px solid #000000"}
                borderRadius={"10px"}
                height={"5vh"}
                placeholder={"Fill in password"}
                name={"loginPassword"}
                value={passwordLogin}
                setter={setPasswordLogin}
              />
              <div
                className="absolute float-right cursor-pointer top-35 right-4"
                onClick={() => handleOnClickIconPassword()}
              >
                <img
                  src={
                    passwordInputType === "password"
                      ? EyePasswordHide
                      : EyePasswordShow
                  }
                  alt="show-hide-password-icon"
                />
              </div>
            </div>
            {isEmptyPassword && !passwordLogin ? (
              <div className="text-red-500 mt-2">This field is required</div>
            ) : null}
            {invalidPasswordMsg ? (
              <div className="text-red-500 mt-2">{invalidPasswordMsg}</div>
            ) : null}
            {isWrongEmailOrPassword ? (
              <div className="text-red-500 mt-2">Wrong email or password</div>
            ) : null}
          </div>
        </div>
        <div className="w-3/5 text-right my-2 cursor-pointer text-sm">
          <p onClick={() => handleClickForgotPassword()}>
            Forgot password?
          </p>
        </div>
        <div
          className="flex justify-center my-2 scale-75 sm:scale-100"
        >
          <ReCAPTCHA
            sitekey="6Le7pvUaAAAAAAiPYg6vfzaD7kFju2jFBEYuvvaK"
            onChange={handleOnChangeCaptcha}
          />
        </div>
        <button
          className="w-3/5 flex items-center justify-center rounded-full text-white cursor-pointer py-2 mt-2 bg-black disabled:opacity-30"
          disabled={!isCaptchaCorrect || !emailLogin || !passwordLogin}
          onClick={() => handleOnClickLoginBtn()}
        >
          {loadingLogin && (<Loader className="inline mr-2 w-5 h-5 text-gray-200 animate-spin dark:text-gray-600 fill-gray-600 dark:fill-gray-300" />)}
          <div>Sign In</div>
        </button>
        <div className="w-3/5 text-center text-sm mt-2">
          <p>
            Don't have an account yet?{" "}
            <span
              className="cursor-pointer font-bold"
              onClick={() => handleClickRegisterHere()}
            >
              Register here
            </span>
          </p>
        </div>
      </div>
    </div>
  );
};

export default Login;
