import React, { useState, useEffect } from 'react'
import moment from 'moment'
import Calendar from 'react-calendar'

import KeyboardArrowDownIcon from '../assets/KeyboardArrowIcon'
import NumberInput from './input/NumberInput'
import TextInput from './input/TextInput'
import RadioInput from './input/RadioInput'
import CheckBoxInput from './input/CheckBoxInput'
import SelectInput from './input/SelectInput'
import Summary from './booking/Summary'
import UserDetails from './booking/UserDetails'

import '../index.css'

function BookingModule({
  values,
  errors,
  touched,
  handleChange,
  handleBlur,
  handleSubmit,
  isSubmitting,
  page,
  bookingConfirmation,
  details,
  availableSlots,
  preferredTimeOptions,
  titleOptions,
  durationOptions,
  countryPhoneCodes,
  services,
  paymentChannelOptions,
  priceCalculation,
  isPrivacyPolicyAgreementChecked,
  setIsPrivacyPolicyAgreementChecked,
}) {
  
  const [accordionOpen, setAccordionOpen] = useState([])
  const [durationFieldName, setDurationFieldName] = useState("Duration")
  const [availableDateList, setAvailableDateList] = useState([])
  availableSlots?.map((slot) => {
    const availableTime = slot && slot.availableTime && slot.availableTime.filter((item) => {
      const dateNow = new Date()
      const dateSlot = new Date(`${slot && slot.date} ${item && item.time}`)
      const dateQuota = item && item.quota
      return dateSlot > dateNow && dateQuota > 0
    })
    slot.availableTime = availableTime
    return slot
  })
  const selectedSlot = availableSlots?.find(slot => slot.date === moment(values.date).format("YYYY-MM-DD"))
  const selectedTime = selectedSlot?.availableTime?.find(item => item.id.toString() === values.time)?.time?.split(":")

  const handleToggleFAQ = (index) => {
    if (accordionOpen.includes(index)) {
      setAccordionOpen(prev => prev.filter(i => i !== index))
    } else {
      setAccordionOpen(prev => ([...prev, index]))
    }
  }

  const handleChangeGuest = (type) => {
    if (type === 'min') {
      if (values.num_guests > 1) {
        handleChange({
          target: {
            value: values.num_guests - 1,
            name: "num_guests",
          }
        })
      }
    } else if (values.num_guests < 10) {
      handleChange({
        target: {
          value: values.num_guests + 1,
          name: "num_guests",
        }
      })
    }
  }

  const availableDates = (selectedOption, durationSlotOptions) => {
    let availableDateList = []
    const selectedSlotOption = durationSlotOptions.find(slot => slot.id == selectedOption)
    if (selectedSlotOption && selectedSlotOption.timeslots && selectedSlotOption.timeslots.length > 0 && selectedSlotOption.timeslots[0]) {
      if (selectedSlotOption.timeslots[0].morning && selectedSlotOption.timeslots[0].morning.length > 0) {
        selectedSlotOption.timeslots[0].morning.forEach(element => {
          if (element.is_available) {
            availableDateList.push(moment(element.date).format("YYYY-MM-DD"))
          }
        });
      }
      if (selectedSlotOption.timeslots[0].afternoon && selectedSlotOption.timeslots[0].afternoon.length > 0) {
        selectedSlotOption.timeslots[0].afternoon.forEach(element => {
          if (element.is_available) {
            availableDateList.push(moment(element.date).format("YYYY-MM-DD"))
          }
        });
      }
      if (selectedSlotOption.timeslots[0].evening && selectedSlotOption.timeslots[0].evening.length > 0) {
        selectedSlotOption.timeslots[0].evening.forEach(element => {
          if (element.is_available) {
            availableDateList.push(moment(element.date).format("YYYY-MM-DD"))
          }
        });
      }
    }
    availableDateList = [...new Set(availableDateList)];
    return availableDateList
  }

  useEffect(() => {
    if (durationOptions && durationOptions.length > 0) {
      const optionTitle = durationOptions && durationOptions[0] && durationOptions[0].title
      const fieldName = optionTitle.split(" - ")
      if (fieldName && fieldName.length > 0 && fieldName[1]) {
        setDurationFieldName(fieldName[0])
      } else {
        setDurationFieldName("Service package")
      }
    }
  }, [durationOptions])

  useEffect(() => {
    const dateList = availableDates(values.duration, durationOptions)
    setAvailableDateList(dateList)
  }, [durationOptions, values.duration])

  return (
    <div className='space-y-4 sm:space-y-6'>
      {page === 4 ? (
        <>
          <p className='text-base sm:text-2xl mb-2 sm:mb-4 text-center'>Thank you for your booking</p>
          <div className='flex flex-col items-center border border-black bg-app-light-grey p-4 rounded-md space-y-2'>
            <p className='text-sm sm:text-base font-bold uppercase text-app-grey'>Booking number confirmation</p>
            <p className='text-base sm:text-2xl'>{bookingConfirmation.number}</p>
          </div>
          <p className='text-center px-2 sm:px-8 text-sm sm:text-base'>
            {bookingConfirmation.description}
          </p>
        </>
      ) : page === 3 ? (
        <>
          <div className='space-y-4'>
            <div className='border-b border-black flex items-center justify-between'>
              <p className='text-base sm:text-2xl mb-2 sm:mb-4'>Booking summary</p>
              <button className='flex gap-2 items-center' onClick={() => handleToggleFAQ(1)}>
                <p className='uppercase text-xs sm:text-sm'>View</p>
                <div className={`${accordionOpen.includes(1) ? "rotate-180" : "rotate-0"} transition-all`}>
                  <KeyboardArrowDownIcon />
                </div>
              </button>
            </div>
            {accordionOpen.includes(1) && (
              <Summary
                details={details}
                date={new Date(values.date).setHours(selectedTime[0], selectedTime[1])}
                num_guests={values.num_guests}
              />
            )}
          </div>

          <div className='space-y-4'>
            <div className='border-b border-black flex items-center justify-between'>
              <p className='text-base sm:text-2xl mb-2 sm:mb-4'>Your details</p>
              <button className='flex gap-2 items-center' onClick={() => handleToggleFAQ(2)}>
                <p className='uppercase text-xs sm:text-sm'>View</p>
                <div className={`${accordionOpen.includes(2) ? "rotate-180" : "rotate-0"} transition-all`}>
                  <KeyboardArrowDownIcon />
                </div>
              </button>
            </div>
            {values?.guests?.map((guest, i) => {
              return (
                <div className='space-y-2'>
                  {accordionOpen.includes(2) && (
                    <UserDetails
                      index={i}
                      titleOptions={titleOptions}
                      values={guest}
                      errors={errors.guests?.[i]}
                      touched={touched.guests?.[i]}
                      handleChange={handleChange}
                      handleBlur={handleBlur}
                      countryPhoneCodes={countryPhoneCodes}
                    />
                  )}
                </div>
              )
            })}
          </div>


          <p className='text-base sm:text-2xl mb-2 sm:mb-4'>Proceed to payment</p>
          <div className='space-y-4 border border-black rounded-xl px-4 py-4'>
            <p className='font-bold text-sm sm:text-lg'>Payment method</p>
            <div className='space-y-4'>
              <RadioInput
                name="payment_method"
                styled
                options={paymentChannelOptions}
                onChange={handleChange}
                onBlur={handleBlur}
                error={(errors?.payment_method && touched?.payment_method) && errors?.payment_method}
                value={values.payment_method}
              />

              {/* <p className='font-bold text-sm sm:text-lg'>Payment details</p>
                            <div className='grid grid-cols-1 sm:grid-cols-2 gap-4'>
                                <NumberInput
                                    name="card_number"
                                    label="Card number"
                                    placeholder="0000 0000 0000 0000"
                                    required
                                    onChange={handleChange}
                                    onBlur={handleBlur}
                                    error={(errors?.card_number && touched?.card_number) && errors.card_number}
                                    value={values.card_number}
                                />
                                <NumberInput
                                    name="cvc"
                                    label="CVC number"
                                    placeholder="123"
                                    required
                                    onChange={handleChange}
                                    onBlur={handleBlur}
                                    error={(errors?.cvc && touched?.cvc) && errors.cvc}
                                    value={values.cvc}
                                />
                                <TextInput
                                    name="card_holder_name"
                                    label="Card holder name"
                                    placeholder="John Doe"
                                    required
                                    onChange={handleChange}
                                    onBlur={handleBlur}
                                    error={(errors?.card_holder_name && touched?.card_holder_name) && errors.card_holder_name}
                                    value={values.card_holder_name}
                                />
                                <TextInput
                                    name="expiry_date"
                                    label="Expiry date"
                                    placeholder="12/25"
                                    required
                                    onChange={handleChange}
                                    onBlur={handleBlur}
                                    error={(errors?.expiry_date && touched?.expiry_date) && errors.expiry_date}
                                    value={values.expiry_date}
                                />
                                <TextInput
                                    name="billing_address"
                                    label="Billing address"
                                    placeholder="Melrose Avenue 198 Block 228 Los Angeles, United States"
                                    required
                                    onChange={handleChange}
                                    onBlur={handleBlur}
                                    error={(errors?.billing_address && touched?.billing_address) && errors.billing_address}
                                    value={values.billing_address}
                                />
                                <NumberInput
                                    name="postal_code"
                                    label="Postal code"
                                    placeholder="10000"
                                    required
                                    onChange={handleChange}
                                    onBlur={handleBlur}
                                    error={(errors?.postal_code && touched?.postal_code) && errors.postal_code}
                                    value={values.postal_code}
                                />
                                <TextInput
                                    name="city"
                                    label="Town/city"
                                    placeholder="Los Angeles"
                                    required
                                    onChange={handleChange}
                                    onBlur={handleBlur}
                                    error={(errors?.city && touched?.city) && errors.city}
                                    value={values.city}
                                />
                            </div> */}
            </div>
          </div>

          <div className='space-y-4 border border-black rounded-xl px-4 py-4 bg-app-light-grey'>
            <div className='text-sm  px-4 flex justify-between'>
              <div>
                <p>Service Price</p>
              </div>
              <p>Rp {new Intl.NumberFormat().format(values.num_guests * details.amount)}</p>
            </div>
            <div className='text-sm  px-4 flex justify-between'>
              <div>
                <p>PPN 11%</p>
              </div>
              <p>Rp {new Intl.NumberFormat().format(priceCalculation && priceCalculation.pTaxPrice)}</p>
            </div>
            <div className='text-sm  px-4 flex justify-between'>
              <div>
                <p>Transaction Fee</p>
              </div>
              <p>Rp {new Intl.NumberFormat().format(priceCalculation && priceCalculation.mTaxPrice)}</p>
            </div>
            <div className='text-sm  px-4 flex justify-between'>
              <div>
                <p>Transaction Tax</p>
              </div>
              <p>Rp {new Intl.NumberFormat().format(priceCalculation && priceCalculation.mFeePrice)}</p>
            </div>
            <div className='font-bold text-sm sm:text-lg px-4 flex justify-between'>
              <div>
                <p>Total Amount</p>
                {/* <p className='text-xs'>11% taxes applies</p> */}
              </div>
              <p>Rp {new Intl.NumberFormat().format(priceCalculation && priceCalculation.finalPrice)}</p>
            </div>

          </div>

          <button type="submit" className='bg-black text-white w-full p-2 rounded-xl text-sm sm:text-base' onClick={handleSubmit}>Make payment</button>
        </>
      ) : page === 2 ? (
        <>
          <p className='text-base sm:text-2xl mb-2 sm:mb-4'>Your cart</p>
          <Summary
            details={details}
            date={new Date(values.date).setHours(selectedTime[0], selectedTime[1])}
            num_guests={values.num_guests}
          />

          <p className='text-base sm:text-2xl mb-2 sm:mb-4'>Your details</p>
          <div className='space-y-4 border border-black rounded-xl px-4 py-4'>
            <TextInput
              name="email"
              label="Email address"
              placeholder="Enter your email address here"
              required
              onChange={handleChange}
              onBlur={handleBlur}
              error={(errors?.email && touched?.email) && errors.email}
              value={values.email}
              infoText="Booking confirmation will be sent to this email address."
            />
          </div>
          {values?.guests?.map((guest, i) => {
            return (
              <div className='space-y-2'>
                <UserDetails
                  index={i}
                  titleOptions={titleOptions}
                  editable
                  values={guest}
                  errors={errors.guests?.[i]}
                  touched={touched.guests?.[i]}
                  handleChange={handleChange}
                  handleBlur={handleBlur}
                  countryPhoneCodes={countryPhoneCodes}
                />
              </div>
            )
          })}
          <CheckBoxInput
            name="agree"
            label={(
              <>
                I agree to <a href="/" className='text-app-blue'>terms and conditions</a> and <a href="/" className='text-app-blue'>privacy policy</a>
              </>
            )}
            onChange={() => setIsPrivacyPolicyAgreementChecked(!isPrivacyPolicyAgreementChecked)}
            checked={isPrivacyPolicyAgreementChecked === true}
          />
          <button className='bg-black text-white w-full p-2 rounded-xl text-sm sm:text-base disabled:opacity-30' disabled={isSubmitting || isPrivacyPolicyAgreementChecked === false} onClick={handleSubmit}>Complete booking</button>

        </>
      ) : (
        <>
          <p className='text-base sm:text-2xl mb-2 sm:mb-4'>Add details</p>
          {services?.length > 1 ? (
            <SelectInput
              name="service"
              className=""
              label={details.label}
              options={services}
              onChange={handleChange}
              error={(errors?.service && touched?.service) && errors?.service}
              value={values?.service}
            />
          ) : (
            <div className='space-y-2 border border-black rounded-xl px-4 py-4 bg-app-light-grey'>
              <p className='uppercase text-xs sm:text-sm text-app-grey font-bold'>{details.label}</p>
              <p className='text-sm sm:text-base'>{details?.title}</p>
            </div>
          )}
          <div className='flex flex-col border-b border-b-black pb-3 gap-2'>
            <p className='font-bold text-sm sm:text-lg'>{durationFieldName}</p>
            <div className='grid grid-cols-2 sm:grid-cols-3 gap-4 sm:gap-6 items-center'>
              <SelectInput
                name='duration'
                className="w-full col-span-2"
                options={durationOptions}
                onChange={handleChange}
                // error={(errors?.duration && touched?.duration) && errors?.duration}
                value={values?.duration}

              />
              <div className='flex flex-row gap-4'>
                <div className='whitespace-nowrap text-xs sm:text-sm flex flex-col'>
                  <p className='font-bold'>Price: </p>
                  <p>Rp {new Intl.NumberFormat().format(details?.amount)}</p>
                </div>
                {/* <div className='whitespace-nowrap text-xs sm:text-sm flex flex-col'>
                  <p className='font-bold'>Available Slots:</p>
                  <p>{details?.quota}</p>
                </div> */}
              </div>
            </div>
            {errors?.duration && touched?.duration && (
              <p className='text-xs text-app-brown'>{durationFieldName} {errors?.duration}</p>
            )}
          </div>
          <div className='space-y-2 border-b border-b-black pb-3'>
            <div className='flex justify-between items-center '>
              <p className='font-bold text-sm sm:text-lg'>Add guest</p>
              <div className='flex gap-4 items-center'>
                <button
                  className='text-sm sm:text-base rounded-full border border-black w-5 h-5 sm:w-6 sm:h-6 flex items-center justify-center'
                  onClick={() => handleChangeGuest('min')}
                >
                  -
                </button>
                <div className='text-sm sm:text-base'>{values.num_guests}</div>
                <button
                  className='text-sm sm:text-base rounded-full border border-black w-5 h-5 sm:w-6 sm:h-6 flex items-center justify-center'
                  onClick={() => handleChangeGuest('plus')}
                >
                  +
                </button>
              </div>
            </div>
            {errors?.num_guests && touched?.num_guests && (
              <p className='text-xs sm:text-sm text-app-brown'>{errors?.num_guests}</p>
            )}
          </div>

          <div className='space-y-4'>
            <p className='font-bold text-sm sm:text-lg'>Pick available dates</p>
            <div>
              <Calendar
                className='app-calendar'
                value={values.date}
                onChange={(date) => handleChange({ target: { name: "date", value: date } })}
                minDate={new Date()}
                tileDisabled={({ activeStartDate, date, view }) => !availableDateList.filter((availableDate) => moment(date).format("YYYY-MM-DD") == availableDate)[0]}
              />
            </div>
            {details.schedule_option && details.schedule_option !== '' && details.schedule_option === 'weekdays-only' && (
              <p className='text-xs sm:text-sm text-app-brown'>* This service is available on selected dates on the weekdays.</p>
            )}
            {details.schedule_option && details.schedule_option !== '' && details.schedule_option === 'weekend-only' && (
              <p className='text-xs sm:text-sm text-app-brown'>* This service is available on selected dates on the weekend.</p>
            )}
            {details && details.schedule_window && details.schedule_window !== '' && (
              <p className='text-xs sm:text-sm text-app-brown'>{`** If feasible, customers should be able to arrive approximately ${details.schedule_window.replace("-", " ")} earlier than the booking time.`}</p>
            )}
            {errors?.date && touched?.date && (
              <p className='text-xs sm:text-sm text-app-brown'>{errors?.date}</p>
            )}
          </div>

          <div className='grid grid-cols-3 gap-10'>
            <SelectInput
              name="preferred_time"
              className="hidden sm:flex"
              label="Preferred Time"
              options={preferredTimeOptions}
              onChange={handleChange}
              error={(errors?.preferred_time && touched?.preferred_time) && errors?.preferred_time}
              value={values?.preferred_time}
            />
            <div className='col-span-3 sm:col-span-2 space-y-4'>
              <p className='font-bold text-sm sm:text-lg'>Available times</p>
              <SelectInput
                name="preferred_time"
                className="flex sm:hidden"
                label="Preferred Time"
                options={preferredTimeOptions}
                onChange={handleChange}
                error={(errors?.preferred_time && touched?.preferred_time) && errors?.preferred_time}
                value={values?.preferred_time}
              />
              <div className='grid grid-cols-3 gap-4 w-fit'>
                {selectedSlot?.availableTime?.length > 0 ? (
                  selectedSlot?.availableTime?.map((time, i) => {
                    return (
                      <div>
                        <input id={time.time} name="time" type="radio" className='hidden peer' value={time.id} onChange={handleChange} checked={time.id?.toString() === values.time} />
                        <label className={`text-xs sm:text-sm text-center inline-block border border-black rounded-xl px-4 py-1 select-none peer-checked:bg-black peer-checked:text-white cursor-pointer`} htmlFor={time.time}>
                          {time.time}
                        </label>
                      </div>
                    )
                  })
                ) : (
                  <p className='text-xs sm:text-base text-app-brown col-span-3'>Sorry, the date you selected has no slot available at this preferred time. Please select another preferred time.</p>
                )}
              </div>
              {errors?.time && touched?.time && (
                <p className='text-xs sm:text-sm text-app-brown'>{errors?.time}</p>
              )}
            </div>
          </div>
          <button type="submit" className='bg-black text-white w-full p-2 rounded-xl text-sm sm:text-base focus:ring focus:ring-slate-300' disabled={isSubmitting} onClick={handleSubmit}>Continue</button>
        </>
      )}
    </div>
  )
}

export default BookingModule