import React from 'react'
import ArrowRightIcon from 'assets/icons/general/ArrowRightIcon'
import useAnimation from 'utils/hooks/useAnimation'

function ServiceCard({
    id,
    title,
    description,
    actionButtonText,
    detailsButtonText
}) {
    useAnimation();

    return (
        <div key={id} className='flex flex-col sm:flex-row space-y-2 sm:space-y-0 sm:space-x-12 bg-app-light-grey px-4 py-6 sm:px-8 sm:py-6 rounded-xl app-animation-translate-start'>
            <div className='flex-1 space-y-4'>
                <p className='font-bold text-sm sm:text-lg'>{title}</p>
                <p className='text-xs sm:text-base'>{description}</p>
            </div>
            <div className='flex sm:flex-col justify-between'>
                <a className="flex space-x-2 items-center whitespace-nowrap text-xs sm:text-sm" href={`/services/${id}`}>
                    <p>{detailsButtonText && detailsButtonText !== '' ? detailsButtonText : 'Explore more'}</p>
                    <div>
                        <ArrowRightIcon />
                    </div>
                </a>
                <a className="whitespace-nowrap text-xs sm:text-sm text-center border border-black rounded-xl px-4 py-2" href={`/booking/${id}`}>
                    {actionButtonText && actionButtonText !== '' ? actionButtonText : 'Book now'}
                </a>
            </div>
        </div>
    )
}

export default ServiceCard