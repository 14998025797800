import React from "react";

export const InputText = (props) => {
  const {
    value,
    placeholder,
    name,
    border,
    borderRadius,
    fontWeight,
    fontSize,
    setter,
    paddingLeft,
    functionSetter,
    phoneNumberState,
    setterPhoneNumber,
    disabled,
    type,
    onKeyDown,
    textAlign,
    className
  } = props;

  return (
    <input
      type={type ? type : "text"}
      className={className}
      id="myInputText"
      name={name}
      value={value}
      placeholder={placeholder}
      onChange={({ target: { value } }) =>
        functionSetter
          ? functionSetter(value, phoneNumberState, setterPhoneNumber)
          : setter
            ? setter(value)
            : null
      }
      disabled={disabled}
      onKeyDown={onKeyDown ? onKeyDown : null}
      style={{
        paddingLeft: paddingLeft ? paddingLeft : "1em",
        border: border ? border : "1px solid #000000",
        borderRadius: borderRadius ? borderRadius : "",
        fontStyle: "normal",
        fontWeight: fontWeight ? fontWeight : 400,
        textAlign: textAlign ? textAlign : "",
        ...props,
      }}
    />
  );
};
