import React from 'react'

function NumberInput({
    className,
    name,
    label,
    placeholder,
    disabled,
    value,
    onChange,
    onBlur,
    error,
}) {
    return (
        <div className={`flex flex-col space-y-2 ${className ? className : ""}`}>
            {label && (
                <label for={name} className='font-bold flex text-sm sm:text-base'>
                    <p>{label}</p>
                </label>
            )}
            <input
                type="number"
                id={name}
                placeholder={placeholder}
                className='border border-app-light-grey-2 rounded-lg bg-transparent px-3 py-2 text-sm sm:text-base'
                disabled={disabled}
                value={value}
                onChange={onChange}
                onBlur={onBlur}
            />
            {error && (
                <p className='text-xs text-app-brown'>{error}</p>
            )}
        </div>
    )
}

export default NumberInput