import React, { useEffect, useState } from 'react'
import { useSelector } from 'react-redux'
import item1 from 'assets/images/item.png'
import { useParams } from 'react-router-dom'
import useFetch from 'utils/hooks/useFetch';
import { GET_SERVICE_DETAILS } from 'api';

function ServicesDetails() {
    const { id } = useParams();
    const { data: service, fetch: getService } = useFetch(GET_SERVICE_DETAILS)
    const [activeTabKey, setActiveTabKey] = useState(1)
    const ourServicesSetting = useSelector((state) => state.ourServicesSetting)
    const termsConditionsSetting = useSelector((state) => state.termsConditionsSetting)
    const currencies = useSelector((state) => state.currencies)
    const generalSetting = useSelector((state) => state.generalSetting)

    const storeCurrency = currencies.filter(item => item?.id === generalSetting.shop_currency_id)[0]
    const detailsButtonText = ourServicesSetting && ourServicesSetting.settings && ourServicesSetting.settings.filter(item => item?.component_name === 'action-button-text')[0].content_text
    const detailsImageBanner = ourServicesSetting && ourServicesSetting.settings && ourServicesSetting.settings.filter(item => item?.component_name === 'details-banner-image')[0].content_file_url
    const detailsTabTitle1 = ourServicesSetting && ourServicesSetting.settings && ourServicesSetting.settings.filter(item => item?.component_name === 'details-tab-title-1')[0].content_text
    const detailsTabTitle2 = ourServicesSetting && ourServicesSetting.settings && ourServicesSetting.settings.filter(item => item?.component_name === 'details-tab-title-2')[0].content_text
    const descTermsCondition = termsConditionsSetting && termsConditionsSetting.settings && termsConditionsSetting.settings.filter(item => item?.component_name === 'description')[0].content_text

    useEffect(() => {
        getService({
            params: {
                service_id: id,
            }
        })
    }, [getService, id])

    return (
        <section className='bg-app-cream flex-1'>
            <div className='app-main-container grid grid-cols-1 sm:grid-cols-2 gap-4 md:gap-8 lg:gap-16'>
                <div>
                    <img
                        className='aspect-[3/4] app-animation-translate-start'
                        src={detailsImageBanner && detailsImageBanner !== '' ? detailsImageBanner : item1}
                        alt="item-1"
                    />
                </div>
                <div className='space-y-4 sm:space-y-8'>
                    <p className='text-lg sm:text-2xl font-bold app-animation-translate-start'>
                        {service?.title}
                    </p>
                    <p className='text-sm sm:text-base app-animation-translate-start'>
                        {service?.description}
                    </p>
                    <div class="mb-4 border-b">
                        <ul
                            class="flex flex-wrap -mb-px text-sm font-medium text-center"
                            id="myTab"
                            data-tabs-toggle="#myTabContent"
                            role="tablist"
                        >
                            <li class="mr-2" role="presentation">
                                <button
                                    class={`inline-block p-4 border-b-2 ${activeTabKey == 1 ? 'border-black' : ''} rounded-t-lg hover:text-gray-600 hover:border-slate-300 dark:hover:text-gray-300`}
                                    id="package-tab"
                                    data-tabs-target="#package"
                                    type="button"
                                    role="tab"
                                    aria-controls="package"
                                    aria-selected="false"
                                    onClick={() => setActiveTabKey(1)}
                                >
                                    {detailsTabTitle1 && detailsTabTitle1 !== '' ? detailsTabTitle1 : 'Package available'}
                                </button>
                            </li>
                            <li class="mr-2" role="presentation">
                                <button
                                    class={`inline-block p-4 border-b-2 ${activeTabKey == 2 ? 'border-black' : ''} rounded-t-lg hover:text-gray-600 hover:border-slate-300 dark:hover:text-gray-300`}
                                    id="terms-conditions-tab"
                                    data-tabs-target="#terms-conditions"
                                    type="button"
                                    role="tab"
                                    aria-controls="terms-conditions"
                                    aria-selected="false"
                                    onClick={() => setActiveTabKey(2)}
                                >
                                    {detailsTabTitle2 && detailsTabTitle2 !== '' ? detailsTabTitle2 : 'Terms and conditions'}
                                </button>
                            </li>
                        </ul>
                    </div>
                    <div id="myTabContent">
                        <div
                            class={`${activeTabKey !== 1 ? 'hidden' : ''} p-4`}
                            id="package"
                            role="tabpanel"
                            aria-labelledby="package-tab"
                        >
                            {service?.packages?.map((item, i) => (
                                <div className="mb-5" key={i}>
                                    <p class="text-base font-bold">
                                        {item.title}
                                    </p>
                                    <p class="text-base">
                                        {storeCurrency && (
                                            item.price.toLocaleString("id-ID", { style: "currency", currency: storeCurrency.code })
                                        )}
                                    </p>
                                </div>
                            ))}
                        </div>
                        <div
                            class={`${activeTabKey !== 2 ? 'hidden' : ''} p-4`}
                            id="terms-conditions"
                            role="tabpanel"
                            aria-labelledby="terms-conditions-tab"
                        >
                            <p class="text-sm">
                                {
                                    descTermsCondition && descTermsCondition !== '' ? descTermsCondition
                                        : 'Lorem ipsum dolor sit amet consectetur. Netus auctor sit dictum nec. Ut at nulla ornare facilisi consectetur. Commodo felis sed vestibulum et. Arcu nibh vulputate odio arcu et. Metus nisi aliquam enim justo. Platea blandit at diam montes purus enim pellentesque quis sagittis. Elit sed tellus bibendum lectus. Risus enim pretium ut convallis. Euismod tempus vitae proin duis.'
                                }
                            </p>
                        </div>
                    </div>
                    <div>
                        <a href={`/booking/${id}`} className='bg-black text-white rounded-xl px-10 py-2 text-xs sm:text-sm app-animation-translate-start'>
                            {detailsButtonText && detailsButtonText !== '' ? detailsButtonText : 'Book now'}
                        </a>
                    </div>
                </div>
            </div>
        </section>
    )
}

export default ServicesDetails