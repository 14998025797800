import React, { useState, useEffect } from 'react'
import { useSelector } from 'react-redux'
import Newsletter from 'components/newsletter/Newsletter'
import { faq } from 'utils/constants/data'
import item1 from 'assets/images/item.png'
import useFetch from 'utils/hooks/useFetch'
import { GET_FAQ_LIST } from 'api'

function FAQ() {
    const FAQSetting = useSelector((state) => state.FAQSetting)
    const faqHeadline = FAQSetting && FAQSetting.settings && FAQSetting.settings.filter(item => item?.component_name === 'headline')[0].content_text
    const faqMainBanner = FAQSetting && FAQSetting.settings && FAQSetting.settings.filter(item => item?.component_name === 'main-banner')[0].content_file_url

    const { data: _faqList, loading: loadingFAQList, fetch: getAllFAQList } = useFetch(GET_FAQ_LIST)
    const [activeTab, setActiveTab] = useState(0)

    useEffect(() => {
        getAllFAQList()
    }, [getAllFAQList])

    return (
        <div className='flex-1'>
            <section>
                <img className='w-full max-h-[45vh] object-cover' src={faqMainBanner ? faqMainBanner : item1} alt="item-1" />
            </section>
            <section className='bg-app-cream'>
                <div className='app-main-container space-y-8'>
                    <p className='text-2xl sm:text-4xl text-app-green font-serif font-bold app-animation-translate-start'>
                        {faqHeadline && faqHeadline !== '' ? faqHeadline : 'Frequently Ask Questions'}
                    </p>
                    <div className='flex flex-col sm:flex-row gap-2 sm:gap-8'>
                        <div className='bg-transparent sm:bg-app-light-grey py-2 sm:py-4 app-animation-translate-start'>
                            <ul className='text-sm sm:text-base flex flex-row sm:flex-col overflow-auto'>
                                {_faqList && _faqList?.length > 0 ? (
                                    _faqList?.map((item, i) => (
                                        <li key={i} onClick={() => setActiveTab(i)} className={`border-b-2 sm:border-b-0 sm:border-l-4 ${i === activeTab ? "border-black font-semibold" : "border-transparent"} px-2 py-1 mx-2 sm:mx-0 sm:pl-4 sm:pr-6 sm:py-3 cursor-pointer whitespace-nowrap`}>
                                            {item?.name}
                                        </li>
                                    ))
                                ) : (
                                    faq.map((item, i) => (
                                        <li key={i} onClick={() => setActiveTab(i)} className={`border-b-2 sm:border-b-0 sm:border-l-4 ${i === activeTab ? "border-black font-semibold" : "border-transparent"} px-2 py-1 mx-2 sm:mx-0 sm:pl-4 sm:pr-6 sm:py-3 cursor-pointer whitespace-nowrap`}>
                                            {item?.name}
                                        </li>
                                    ))
                                )}
                            </ul>
                        </div>
                        <div className='py-2 px-2 sm:px-0 sm:py-6 space-y-4 sm:space-y-6'>
                            {_faqList && _faqList?.length > 0 ? (
                                _faqList?.[activeTab]?.question_list?.map((item, i) =>
                                (
                                    <div key={i} className='text-sm sm:text-base space-y-2'>
                                        <p className='font-bold'>{item?.question}</p>
                                        <p>{item?.answer}</p>
                                    </div>
                                ))
                            ) : (
                                faq?.[activeTab]?.list?.map((item, i) => (
                                    <div key={i} className='text-sm sm:text-base space-y-2'>
                                        <p className='font-bold'>{item?.question}</p>
                                        <p>{item?.answer}</p>
                                    </div>
                                ))
                            )}
                        </div>
                    </div>
                </div>
            </section>
            <Newsletter />
        </div>
    )
}

export default FAQ