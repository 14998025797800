import React, { useState } from "react";
import { useParams } from "react-router-dom";

// Icons and Images
import PasswordLogin from "assets/icons/user_authentication/PasswordLogin.png";
import EyePasswordHide from "assets/icons/user_authentication/EyePasswordHide.png";
import EyePasswordShow from "assets/icons/user_authentication/EyePasswordShow.png";

// Components
import { InputText } from "components/input/Inputs";

// Apis
import useFetch from "utils/hooks/useFetch";
import { RESET_PASSWORD_USER } from "api";

// Helpers
import { timer } from "utils/helpers";

const ResetPassword = () => {

  const { data: resetPasswordData, loading: loadingResetPassword, fetch: resetPasswordUser } = useFetch(RESET_PASSWORD_USER)

  const [passwordRegister, setRegisterPassword] = useState("");
  const [isResetPasswordSuccess, setResetPasswordSuccess] = useState(false);
  const [isResetPasswordFailed, setResetPasswordFailed] = useState(false);
  const [reTypePasswordRegister, setReTypePasswordRegister] = useState("");
  const [passwordInputType, setPasswordInputType] = useState("password");
  const { userToken } = useParams();

  const handleOnClickIconPassword = () => {
    if (passwordInputType === "password") {
      setPasswordInputType("text");
    } else {
      setPasswordInputType("password");
    }
  };

  const handleClickSubmitBtn = async () => {
    const data = {
      token: userToken,
      password: passwordRegister,
    }
    const result = await resetPasswordUser({ data });
    if (result && result.success) {
      const beforeFunction = () => {
        return setResetPasswordSuccess(true)
      };
      const afterFunction = () => {
        return window.location.assign("/")
      };
      timer(beforeFunction, afterFunction, 3000);
    } else {
      const beforeFunction = () => {
        return setResetPasswordFailed(true)
      };
      const afterFunction = () => {
        return setResetPasswordFailed(false)
      };
      timer(beforeFunction, afterFunction, 3000);
    }
  };

  return (
    <div className="flex flex-col bg-app-cream item-center py-32 space-y-6">
      <div className="text-center font-bold text-2xl md:text-4xl">Reset Password</div>
      {isResetPasswordSuccess && (
        <div className="flex flex-col items-center my-2">
          <div className="w-1/2 lg:w-1/3 flex items-center justify-center text-green-500 bg-green-100 rounded-md p-3 mb-2">
            <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-info-circle" viewBox="0 0 16 16">
              <path d="M8 15A7 7 0 1 1 8 1a7 7 0 0 1 0 14m0 1A8 8 0 1 0 8 0a8 8 0 0 0 0 16" />
              <path d="m8.93 6.588-2.29.287-.082.38.45.083c.294.07.352.176.288.469l-.738 3.468c-.194.897.105 1.319.808 1.319.545 0 1.178-.252 1.465-.598l.088-.416c-.2.176-.492.246-.686.246-.275 0-.375-.193-.304-.533zM9 4.5a1 1 0 1 1-2 0 1 1 0 0 1 2 0" />
            </svg>
            <span className="ml-2">
              Password has been successfully changed
            </span>
          </div>
        </div>
      )}
      {isResetPasswordFailed && (
        <div className="flex flex-col items-center my-2">
          <div className="w-1/2 lg:w-1/3 flex items-center justify-center text-red-500 bg-red-100 rounded-md p-3 mb-2">
            <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-info-circle" viewBox="0 0 16 16">
              <path d="M8 15A7 7 0 1 1 8 1a7 7 0 0 1 0 14m0 1A8 8 0 1 0 8 0a8 8 0 0 0 0 16" />
              <path d="m8.93 6.588-2.29.287-.082.38.45.083c.294.07.352.176.288.469l-.738 3.468c-.194.897.105 1.319.808 1.319.545 0 1.178-.252 1.465-.598l.088-.416c-.2.176-.492.246-.686.246-.275 0-.375-.193-.304-.533zM9 4.5a1 1 0 1 1-2 0 1 1 0 0 1 2 0" />
            </svg>
            <span className="ml-2">
              Reset password is failed
            </span>
          </div>
        </div>
      )}
      <div className="flex flex-col items-center my-2">
        <div className="w-1/2 lg:w-1/3 flex flex-row items-center gap-2 mb-2">
          <img src={PasswordLogin} alt="password-icon" />
          <div className="text-sm md:text-base">Password</div>
        </div>
        <div className="w-1/2 lg:w-1/3 flex relative items-center">
          <InputText
            className="text-sm md:text-base"
            type={passwordInputType}
            width={"100%"}
            border={"1px solid #000000"}
            borderRadius={"10px"}
            height={"5vh"}
            placeholder={"Fill in password"}
            name={"registerPassword"}
            value={passwordRegister}
            setter={setRegisterPassword}
          />
          <div
            className="absolute float-right cursor-pointer top-35 right-4"
            onClick={() => handleOnClickIconPassword()}
          >
            <img
              src={
                passwordInputType === "password"
                  ? EyePasswordHide
                  : EyePasswordShow
              }
              alt="show-hide-password-icon"
            />
          </div>
        </div>
      </div>
      <div className="flex flex-col items-center my-2">
        <div className="w-1/2 lg:w-1/3 flex flex-row items-center gap-2 mb-2">
          <img src={PasswordLogin} alt="password-icon" />
          <div className="text-sm md:text-base">Retype Password</div>
        </div>
        <div className="w-1/2 lg:w-1/3 flex relative items-center">
          <InputText
            className="text-sm md:text-base"
            type={passwordInputType}
            width={"100%"}
            border={"1px solid #000000"}
            borderRadius={"10px"}
            height={"5vh"}
            placeholder={"Re-type password"}
            name={"registerPassword"}
            value={reTypePasswordRegister}
            setter={setReTypePasswordRegister}
          />
          <div
            className="absolute float-right cursor-pointer top-35 right-4"
            onClick={() => handleOnClickIconPassword()}
          >
            <img
              src={
                passwordInputType === "password"
                  ? EyePasswordHide
                  : EyePasswordShow
              }
              alt="show-hide-password-icon"
            />
          </div>
        </div>

      </div>
      <div className="flex flex-col items-center my-2">
        <button
          className="w-1/2 lg:w-1/3 flex justify-center rounded-full text-white cursor-pointer py-2 bg-black disabled:opacity-30"
          disabled={!passwordRegister}
          onClick={() => handleClickSubmitBtn()}
        >
          Submit
        </button>
      </div>
    </div>
  );
};

export default ResetPassword;
