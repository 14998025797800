import React from 'react'
import NumberInput from '../input/NumberInput'
import CheckBoxInput from '../input/CheckBoxInput'
import TextInput from '../input/TextInput'
import TextAreaInput from '../input/TextAreaInput'
import DateInput from '../input/DateInput'
import SelectInput from '../input/SelectInput'
import RadioInput from '..//input/RadioInput'

function UserDetails({
    index,
    titleOptions,
    countryPhoneCodes,
    editable,
    values,
    errors,
    touched,
    handleChange,
    handleBlur,
}) {
    return (
        <div className='space-y-4 border border-black rounded-xl px-4 py-4'>
            <p className='text-sm sm:text-lg font-bold'>User {index + 1}</p>
            <RadioInput
                name={`guests[${index}].title`}
                label="Title"
                options={titleOptions}
                disabled={!editable}
                onChange={handleChange}
                onBlur={handleBlur}
                value={values.title}
                error={(errors?.title && touched?.title) && errors.title}
            />
            <TextInput
                name={`guests[${index}].first_name`}
                label="First name"
                required={true}
                placeholder="Fill in your first name"
                disabled={!editable}
                onChange={handleChange}
                onBlur={handleBlur}
                error={(errors?.first_name && touched?.first_name) && errors.first_name}
                value={values.first_name}
            />
            <TextInput
                name={`guests[${index}].last_name`}
                label="Last name"
                required={true}
                placeholder="Fill in your last name"
                disabled={!editable}
                onChange={handleChange}
                onBlur={handleBlur}
                error={(errors?.last_name && touched?.last_name) && errors.last_name}
                value={values.last_name}
            />
            <DateInput
                name={`guests[${index}].date_of_birth`}
                label="Date of birth"
                placeholder="Pick your date of birth"
                disabled={!editable}
                onChange={handleChange}
                onBlur={handleBlur}
                error={(errors?.date_of_birth && touched?.date_of_birth) && errors.date_of_birth}
                value={values.date_of_birth}
            />
            <SelectInput
                name={`guests[${index}].gender`}
                label="Gender"
                options={[
                    {
                        id: "male",
                        text: "Male"
                    },
                    {
                        id: "female",
                        text: "Female"
                    },
                    {
                        id: "other",
                        text: "Other"
                    },
                ]}
                disabled={!editable}
                onChange={handleChange}
                onBlur={handleBlur}
                error={(errors?.gender && touched?.gender) && errors.gender}
                value={values.gender}
            />
            <div className='space-y-2'>
                <label for="phone_number" className='font-bold flex text-sm sm:text-base'>
                    <p>Phone number</p>
                </label>
                <div className='grid grid-cols-1 sm:grid-cols-3 gap-2 sm:gap-4 items-start'>
                    <SelectInput
                        name={`guests[${index}].country_phone_code`}
                        options={countryPhoneCodes}
                        disabled={!editable}
                        onChange={handleChange}
                        onBlur={handleBlur}
                        error={(errors?.country_phone_code && touched?.country_phone_code) && errors.country_phone_code}
                        value={values.country_phone_code}
                    />
                    <NumberInput
                        className="col-span-1 sm:col-span-2"
                        name={`guests[${index}].phone_number`}
                        placeholder="Fill in your phone number"
                        disabled={!editable}
                        onChange={handleChange}
                        onBlur={handleBlur}
                        error={(errors?.phone_number && touched?.phone_number) && errors.phone_number}
                        value={values.phone_number}
                    />
                </div>
            </div>
            <CheckBoxInput
                name={`guests[${index}].whatsapp`}
                label="This number is available on Whatsapp"
                disabled={!editable}
                onChange={handleChange}
                onBlur={handleBlur}
                error={(errors?.whatsapp && touched?.whatsapp) && errors.whatsapp}
                value={values.whatsapp}
                checked={values.whatsapp}
            />
            <TextInput
                name={`guests[${index}].address`}
                label="Address"
                placeholder="Fill in your address"
                disabled={!editable}
                onChange={handleChange}
                onBlur={handleBlur}
                error={(errors?.address && touched?.address) && errors.address}
                value={values.address}
            />
            <TextAreaInput
                name={`guests[${index}].notes`}
                label="Notes"
                placeholder="Enter your notes or other information"
                rows={8}
                disabled={!editable}
                onChange={handleChange}
                onBlur={handleBlur}
                error={(errors?.notes && touched?.notes) && errors.notes}
                value={values.notes}
            />
        </div>
    )
}

export default UserDetails