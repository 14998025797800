import { useEffect } from 'react'

const useAnimation = () => {
    useEffect(() => {
        const elements = [
            ...document.querySelectorAll('.app-animation-translate-start'),
            ...document.querySelectorAll('.app-animation-scale-start'),
            ...document.querySelectorAll('.app-animation-opacity-start'),
        ]
        const options = {
            root: null,
            threshold: 0.2
        }

        const observer = new IntersectionObserver(function (entries, observer) {
            entries.forEach(entry => {
                if (entry.isIntersecting) {
                    entry.target.classList.add("app-animation-end")
                    entry.target.classList.remove("app-animation-translate-start", "app-animation-scale-start", "app-animation-opacity-start", "app-animation-translate-start", "app-animation-translate-start")
                    observer.unobserve(entry.target);
                } else {
                    return;
                }
            });
        }, options);

        elements.forEach(element => observer.observe(element))
        return () => observer.disconnect();
    }, []);
}

export default useAnimation;