import React from 'react'

function CheckBoxInput({
    name,
    label,
    disabled,
    value,
    onChange,
    onBlur,
    error,
}) {
    return (
        <div className='flex items-center space-x-2 text-xs sm:text-sm'>
            <input
                id={name}
                name={name}
                type="checkbox"
                disabled={disabled}
                value={value}
                onChange={onChange}
                onBlur={onBlur}
                checked={value}
            />
            <label for={name}>
                {label}
            </label>
            {error && (
                <p className='text-xs text-app-brown'>{error}</p>
            )}
        </div>
    )
}

export default CheckBoxInput