import React from 'react'
import { useFormik } from "formik"
import * as Yup from "yup"
import { useSelector } from 'react-redux'
import useFetch from 'utils/hooks/useFetch'
import { SUBSCRIBE_NEWSLETTER } from 'api'

function Newsletter() {
    const newsletterSetting = useSelector((state) => state.newsletterSetting)
    const { fetch: subscribeNewsletter } = useFetch(SUBSCRIBE_NEWSLETTER)

    const newsletterHeadline = newsletterSetting && newsletterSetting.settings && newsletterSetting.settings.filter(item => item?.component_name === 'headline')[0].content_text
    const inputPlaceholder = newsletterSetting && newsletterSetting.settings && newsletterSetting.settings.filter(item => item?.component_name === 'input-placeholder-text')[0].content_text
    const accessButtonText = newsletterSetting && newsletterSetting.settings && newsletterSetting.settings.filter(item => item?.component_name === 'action-button-text')[0].content_text

    const formik = useFormik({
        initialValues: {
            email_subscriber: ''
        },
        validationSchema: Yup.object().shape({
            email_subscriber: Yup.string().required('Please input your email address').email('Invalid email format')
        }),
        validateOnBlur: false,
        validateOnChange: false,
        onSubmit: async (values, { setSubmitting }) => {
            await subscribeNewsletter({
                data: {
                    email: values?.email_subscriber
                }
            })
            window.location.reload()
        },
    });

    return (
        <section>
            <div className='max-w-xl mx-auto py-12 px-4 space-y-8 flex flex-col items-center justify-center'>
                <p className='text-base sm:text-xl font-semibold text-center max-w-md app-animation-translate-start'>
                    {newsletterHeadline && newsletterHeadline !== '' ? newsletterHeadline : 'Lorem ipsum dolor sit amet lorem ipsum dolor sit amet'}
                </p>
                <div className='text-sm sm:text-base flex gap-2 sm:gap-4 app-animation-translate-start'>
                    <div className='flex flex-col space-y-2'>
                        <input
                            id='email_subscriber'
                            name='email_subscriber'
                            className='flex-1 border border-app-light-grey-2 rounded-xl px-3 py-2'
                            placeholder={inputPlaceholder && inputPlaceholder !== '' ? inputPlaceholder : 'Fill in your email address'}
                            onChange={formik.handleChange}
                            value={formik.values.email_subscriber}
                        />
                        {formik.errors.email_subscriber && (
                            <p className='text-xs text-app-brown'>{formik.errors.email_subscriber}</p>
                        )}
                    </div>
                    <div className='flex flex-col space-y-2'>
                        <button
                            onClick={formik.handleSubmit}
                            className='px-4 sm:px-8 py-2 bg-black text-white rounded-xl'
                        >
                            {accessButtonText && accessButtonText !== '' ? accessButtonText : 'Subscribe'}
                        </button>
                    </div>
                </div>
            </div>
        </section>
    )
}

export default Newsletter