import moment from 'moment';
import React, { useEffect, useState } from 'react'
import { Navigate, useNavigate } from 'react-router-dom';
import useFetch from 'utils/hooks/useFetch';

// Components
import PendingPayment from 'components/payment/PendingPayment';
import SuccessPayment from 'components/payment/SuccessPayment';

// Apis
import { GET_ORDER_BY_ORDER_ID } from 'api';

function PaymentPending() {
  const { data: order, loading: loadingOrder, error: errorOrder, fetch: fetchOrder } = useFetch(GET_ORDER_BY_ORDER_ID)
  const [timer, setTimer] = useState({
    days: 0,
    hours: 0,
    minutes: 0,
    seconds: 0,
  })
  const urlParams = new URLSearchParams(window.location.search);
  const orderId = urlParams.get('order_id');
  const transactionStatus = urlParams.get('transaction_status');
  const status = transactionStatus && transactionStatus.toLocaleLowerCase()

  useEffect(() => {
    fetchOrder({
      params: {
        order_id: orderId
      }
    })
  }, [orderId, fetchOrder])

  const handleCheckPayment = () => {
    if (!loadingOrder && order?.mpayment_link) {
      window.location.assign(order.mpayment_link)
    }
  }

  const countDownDate = order?.transaction_date ? moment(order?.transaction_date).add(1, 'days') : undefined

  useEffect(() => {
    if (countDownDate) {
      const x = setInterval(function () {
        const now = new Date().getTime();
        const distance = countDownDate - now;

        const days = Math.floor(distance / (1000 * 60 * 60 * 24));
        const hours = Math.floor((distance % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60));
        const minutes = Math.floor((distance % (1000 * 60 * 60)) / (1000 * 60));
        const seconds = Math.floor((distance % (1000 * 60)) / 1000);

        setTimer({
          days,
          hours,
          minutes,
          seconds,
        })

        if (distance < 0) {
          clearInterval(x);
          setTimer({
            days: 0,
            hours: 0,
            minutes: 0,
            seconds: 0,
          })
        }
      }, 1000);
    }
  }, [countDownDate])

  if (!orderId || !transactionStatus) {
    return (
      <Navigate to="/" />
    )
  }

  switch (status) {
    case 'pending':
      return <PendingPayment order={order} timer={timer} handleCheckPayment={handleCheckPayment} />
    case 'settlement':
      return <SuccessPayment />
    default:
      return <PendingPayment order={order} timer={timer} handleCheckPayment={handleCheckPayment} />
  }

}

export default PaymentPending