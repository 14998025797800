import React from 'react'

function ConfirmationModule({
    type,
    bookingConfirmation,
    timer,
}) {
    if (type === "success") {
        return (
            <div className='space-y-4 sm:space-y-6'>
                <p className='text-base sm:text-2xl mb-2 sm:mb-4 text-center font-semibold'>Payment success</p>
                <p className='text-base sm:text-2xl mb-2 sm:mb-4 text-center'>Thank you for your booking</p>
                <div className='flex flex-col items-center border border-black bg-app-light-grey p-4 rounded-md space-y-2'>
                    <p className='text-sm sm:text-base font-bold uppercase text-app-grey'>Booking number confirmation</p>
                    <p className='text-base sm:text-2xl uppercase'>{bookingConfirmation.number}</p>
                </div>
                <p className='text-center px-2 sm:px-8 text-sm sm:text-base'>
                    {bookingConfirmation.description}
                </p>
            </div>
        )
    }

    if (type === "pending") {
        return (
            <div className='space-y-4 sm:space-y-6'>
                <p className='text-base sm:text-2xl mb-2 sm:mb-4 text-center'>Waiting for payment</p>
                <div className='flex flex-col items-center border border-black bg-app-light-grey p-4 rounded-md space-y-2'>
                    <p className='text-sm sm:text-base font-bold uppercase text-app-grey'>Time remaining for payment confirmation</p>
                    <p><span className='font-bold'>{timer?.hours}</span> hrs : <span className='font-bold'>{timer?.minutes}</span> mns : <span className='font-bold'>{timer?.seconds}</span> secs</p>
                </div>
                <p className='text-center px-2 sm:px-8 text-sm sm:text-base'>
                    Your transaction will be processed once the payment is verified
                </p>
            </div>
        )
    }

    return (
        <div className='space-y-4 sm:space-y-6'>
            <p className='text-base sm:text-2xl mb-2 sm:mb-4 text-center'>Payment failed</p>
            <p className='text-center px-2 sm:px-8 text-sm sm:text-base'>
                Unfortunately, we can't process your payment.
                Please check your internet connection and try again.
            </p>
        </div>
    )
}

export default ConfirmationModule