import React from 'react'

import ReceiptClockIcon from 'assets/icons/general/ReceiptClockIcon'

function SuccessPayment({ order }) {

  return (
    <section>
      <div className='max-w-6xl mx-auto px-4 py-48 sm:py-52 space-y-8'>
        <div className='flex flex-col items-center space-y-4'>
          <ReceiptClockIcon />
          <p className='text-xl sm:text-2xl font-bold text-center'>Thank you for your payment</p>
        </div>
        <p className='text-xs sm:text-sm text-center sm:mx-16'>
          Your transaction has been processed sucessfully, please check your email for confirmation
        </p>
      </div>
    </section>
  )

}

export default SuccessPayment