import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";

// React Google Recaptcha
import ReCAPTCHA from "react-google-recaptcha";

// Icons and Images
import EmailLogin from "assets/icons/user_authentication/EmailLogin.png";
import PasswordLogin from "assets/icons/user_authentication/PasswordLogin.png";
import EyePasswordHide from "assets/icons/user_authentication/EyePasswordHide.png";
import EyePasswordShow from "assets/icons/user_authentication/EyePasswordShow.png";
import UsernameLogin from "assets/icons/user_authentication/UsernameLogin.png";
import CloseFullscreenBtn from "assets/icons/general/CloseFullscreenBtn.png";
import Loader from "assets/icons/general/Loader";

// Components
import { InputText } from "components/input/Inputs";

// Apis
import useFetch from "utils/hooks/useFetch";
import { REGISTER_USER } from "api";

// Helpers
import {
  validateEmail,
  validatePassword,
} from "utils/helpers";


const Register = ({
  isMobileSize,
  setOpenLoginUser,
  setOpenRegisterUser,
}) => {
  const { data: registerData, loading: loadingRegister, fetch: registerUser } = useFetch(REGISTER_USER)
  const storeDetails = useSelector((state) => state.storeDetails);

  const dispatch = useDispatch();
  const [usernameRegister, setUsernameRegister] = useState();
  const [emailRegister, setEmailRegister] = useState();
  const [passwordRegister, setRegisterPassword] = useState();
  const [reTypePasswordRegister, setReTypePasswordRegister] = useState();
  const [isCaptchaCorrect, setCaptchaCorrect] = useState(false);
  const [passwordInputType, setPasswordInputType] = useState("password");

  const [invalidEmail, setInvalidEmail] = useState(false);
  const [invalidEmailFormat, setInvalidEmailFormat] = useState(false);
  const [invalidUsername, setInvalidUsername] = useState(false);
  const [invalidPassword, setInvalidPassword] = useState(false);
  const [invalidReTypePassword, setInvalidReTypePassword] = useState(false);
  const [
    invalidMatchBetweenPasswordAndRetypePassword,
    setInvalidMatchBetweenPasswordAndRetypePassword,
  ] = useState(false);
  const [invalidPasswordMsg, setInvalidPasswordMsg] = useState("");

  const handleOnClickIconPassword = () => {
    if (passwordInputType === "password") {
      setPasswordInputType("text");
    } else {
      setPasswordInputType("password");
    }
  };

  const handleOnChangeCaptcha = (value) => {
    if (value) {
      setCaptchaCorrect(true);
    } else {
      setCaptchaCorrect(false);
    }
  };

  const handleValidationRegister = () => {
    let invalidEmail = false;
    let invalidEmailFormat = false;
    let invalidUsername = false;
    let invalidPassword = false;
    let invalidReTypePassword = false;
    let invalidMatchBetweenPasswordAndRetypePassword = false;
    let invalidPassMsg = "";

    if (!usernameRegister) {
      invalidUsername = true;
    }
    if (!emailRegister) {
      invalidEmail = true;
    } else {
      if (validateEmail(emailRegister) !== true) {
        invalidEmailFormat = true;
      }
    }
    if (!passwordRegister || !reTypePasswordRegister) {
      if (!passwordRegister) {
        invalidPassword = true;
        invalidPassMsg = "This field is required";
      }
      if (!reTypePasswordRegister) {
        invalidReTypePassword = true;
      }
    } else {
      // validate password format
      const isValidPassword = validatePassword(passwordRegister);
      if (!isValidPassword) {
        invalidPassword = true;
        invalidPassMsg =
          "Password should be at least 8 characters long, contain at least one uppercase letter, one lowercase letter, and one number.";
      } else {
        // check if password and retype password not match
        if (passwordRegister !== reTypePasswordRegister) {
          invalidMatchBetweenPasswordAndRetypePassword = true;
        }
      }
    }

    setInvalidEmail(invalidEmail);
    setInvalidEmailFormat(invalidEmailFormat);
    setInvalidUsername(invalidUsername);
    setInvalidPassword(invalidPassword);
    setInvalidReTypePassword(invalidReTypePassword);
    setInvalidMatchBetweenPasswordAndRetypePassword(
      invalidMatchBetweenPasswordAndRetypePassword
    );
    setInvalidPasswordMsg(invalidPassMsg);

    if (
      invalidEmail !== true &&
      invalidEmailFormat !== true &&
      invalidUsername !== true &&
      invalidPassword !== true &&
      invalidReTypePassword !== true &&
      invalidMatchBetweenPasswordAndRetypePassword !== true
    ) {
      return true;
    } else {
      return false;
    }
  };

  const handleOnClickRegisterBtn = async () => {
    const isValid = handleValidationRegister();
    if (isValid) {
      const data = {
        email: emailRegister,
        username: usernameRegister,
        password: passwordRegister,
      };
      const result = await registerUser({ data });
      if (result && result.id) {
        setOpenRegisterUser(false)
        setOpenLoginUser(true)
      }
    }
  };

  const handleClickLoginHere = () => {
    setOpenLoginUser(true)
    setOpenRegisterUser(false)
  }

  return (
    <div className="flex justify-center items-center fixed text-left top-0 left-0 right-0 bottom-0 z-[999] bg-black/50">
      <div className="w-3/4 lg:w-2/5 px-4 py-8 lg:py-10 flex flex-col justify-center items-center bg-app-cream rounded-3xl shadow-2xl">
        <div
          className="w-full flex justify-end mr-6 mb-4 lg:mr-8"
          onClick={() => setOpenRegisterUser(false)}
        >
          <img className="w-4 h-4 sm:w-5 sm:h-5 lg:w-7 lg:h-7 cursor-pointer" src={CloseFullscreenBtn} alt="close-fullscreen-btn" />
        </div>
        <div className="flex-1 flex flex-col items-center w-full max-h-[50vh] sm:max-h-none overflow-y-auto overflow-x-hidden">
          <div className="font-bold text-2xl md:text-4xl text-center">Create your account</div>
          <div className="text-base md:text-base font-semibold mb-4 text-center">Register to Creme and Coral</div>
          <div className="w-3/4 sm:w-3/5 mt-4">
            <div className="flex items-center gap-2 mb-2">
              <img src={UsernameLogin} alt="username-icon" />
              <div className="text-sm md:text-base">Username</div>
            </div>
            <InputText
              className="text-sm md:text-base"
              width={"100%"}
              border={"1px solid #000000"}
              borderRadius={"10px"}
              height={"5vh"}
              placeholder={"Fill in username"}
              name={"registerUsername"}
              value={usernameRegister}
              setter={setUsernameRegister}
            />
            {invalidUsername ? (
              <div className="text-red-500 mt-2">This field is required</div>
            ) : null}
          </div>
          <div className="w-3/4 sm:w-3/5 mt-4">
            <div className="flex items-center gap-2 mb-2">
              <img src={EmailLogin} alt="email-icon" />
              <div className="text-sm md:text-base">Email</div>
            </div>
            <InputText
              className="text-sm md:text-base"
              width={"100%"}
              border={"1px solid #000000"}
              borderRadius={"10px"}
              height={"5vh"}
              placeholder={"Fill in email address"}
              name={"registerEmailAddress"}
              value={emailRegister}
              setter={setEmailRegister}
            />
            {invalidEmail || invalidEmailFormat ? (
              <div className="text-red-500 mt-2">
                {invalidEmail
                  ? "This field is required"
                  : "Please input a valid email format"}
              </div>
            ) : null}
          </div>
          <div className="w-3/4 sm:w-3/5 mt-4">
            <div>
              <div className="flex items-center gap-2 mb-2">
                <img src={PasswordLogin} alt="password-icon" />
                <div className="text-sm md:text-base">Password</div>
              </div>
              <div className="flex relative items-center">
                <InputText
                  type={passwordInputType}
                  className="text-sm md:text-base"
                  width={"100%"}
                  border={"1px solid #000000"}
                  borderRadius={"10px"}
                  height={"5vh"}
                  placeholder={"Fill in password"}
                  name={"registerPassword"}
                  value={passwordRegister}
                  setter={setRegisterPassword}
                />
                <div
                  className="absolute float-right cursor-pointer top-35 right-4"
                  onClick={() => handleOnClickIconPassword()}
                >
                  <img
                    src={
                      passwordInputType === "password"
                        ? EyePasswordHide
                        : EyePasswordShow
                    }
                    alt="show-hide-password-icon"
                  />
                </div>
              </div>
              {invalidPassword && invalidPasswordMsg ? (
                <div className="text-red-500 mt-2">{invalidPasswordMsg}</div>
              ) : null}
            </div>
          </div>
          <div className="w-3/4 sm:w-3/5 mt-4">
            <div>
              <div className="flex items-center gap-2 mb-2">
                <img src={PasswordLogin} alt="password-icon" />
                <div className="text-sm md:text-base">Retype Password</div>
              </div>
              <div className="flex relative items-center">
                <InputText
                  type={passwordInputType}
                  className="text-sm md:text-base"
                  width={"100%"}
                  border={"1px solid #000000"}
                  borderRadius={"10px"}
                  height={"5vh"}
                  placeholder={"Re-type password"}
                  name={"registerPassword"}
                  value={reTypePasswordRegister}
                  setter={setReTypePasswordRegister}
                />
                <div
                  className="absolute float-right cursor-pointer top-35 right-4"
                  onClick={() => handleOnClickIconPassword()}
                >
                  <img
                    src={
                      passwordInputType === "password"
                        ? EyePasswordHide
                        : EyePasswordShow
                    }
                    alt="show-hide-password-icon"
                  />
                </div>
              </div>
              {invalidReTypePassword ||
                invalidMatchBetweenPasswordAndRetypePassword ? (
                <div className="text-red-500 mt-2">
                  {invalidReTypePassword
                    ? "This field is required"
                    : "Password did not match"}
                </div>
              ) : null}
            </div>
          </div>
          <div
            className="flex justify-center my-4 scale-75 sm:scale-100"
          >
            <ReCAPTCHA
              sitekey="6Le7pvUaAAAAAAiPYg6vfzaD7kFju2jFBEYuvvaK"
              onChange={handleOnChangeCaptcha}
            />
          </div>
          <button
            className="w-3/4 sm:w-3/5 flex items-center justify-center rounded-full text-white cursor-pointer py-2 bg-black disabled:opacity-30"
            disabled={
              !isCaptchaCorrect ||
              !usernameRegister ||
              !emailRegister ||
              !passwordRegister ||
              !reTypePasswordRegister
            }
            onClick={() =>
              !isCaptchaCorrect ||
                !usernameRegister ||
                !emailRegister ||
                !passwordRegister ||
                !reTypePasswordRegister
                ? null
                : handleOnClickRegisterBtn()
            }
          >
            {loadingRegister && (<Loader className="inline mr-2 w-5 h-5 text-gray-200 animate-spin dark:text-gray-600 fill-gray-600 dark:fill-gray-300" />)}
            <div>Register Account</div>
          </button>
          <div className="w-3/4 sm:w-3/5 text-center text-sm mt-2">
            <p>
              Already have an account? &nbsp;
              <span
                className="cursor-pointer font-bold"
                onClick={() => handleClickLoginHere()}
              >
                Login here
              </span>
            </p>
          </div>
        </div>

      </div>
    </div>
  );
};

export default Register;
