import React from 'react'

function BuildingIcon({
    className = "h-6 w-6 fill-black"
}) {
    return (
        <svg className={className} viewBox="0 0 34 30" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M18.6667 1.67113e-07C19.5077 -0.000265965 20.3176 0.317344 20.9343 0.889162C21.5509 1.46098 21.9286 2.24474 21.9917 3.08333L22 3.33333V10H27C27.841 9.99973 28.651 10.3173 29.2676 10.8892C29.8842 11.461 30.262 12.2447 30.325 13.0833L30.3334 13.3333V26.6667H32C32.4248 26.6671 32.8334 26.8298 33.1423 27.1214C33.4512 27.413 33.6371 27.8116 33.662 28.2357C33.6869 28.6597 33.5489 29.0773 33.2762 29.4031C33.0036 29.7288 32.6169 29.9382 32.195 29.9883L32 30H2.00003C1.57523 29.9995 1.16664 29.8369 0.857745 29.5453C0.548851 29.2536 0.362967 28.8551 0.338072 28.431C0.313177 28.0069 0.45115 27.5894 0.7238 27.2636C0.996451 26.9379 1.3832 26.7285 1.80503 26.6783L2.00003 26.6667H3.66669V3.33333C3.66643 2.49237 3.98404 1.68239 4.55585 1.06575C5.12767 0.449118 5.91143 0.0714057 6.75002 0.00833369L7.00003 1.67113e-07H18.6667ZM27 13.3333H22V26.6667H27V13.3333ZM18.6667 3.33333H7.00003V26.6667H18.6667V3.33333ZM15.3334 20V23.3333H10.3334V20H15.3334ZM15.3334 13.3333V16.6667H10.3334V13.3333H15.3334ZM15.3334 6.66667V10H10.3334V6.66667H15.3334Z" />
        </svg>
    )
}

export default BuildingIcon