import React from 'react'

import ReceiptClockIcon from 'assets/icons/general/ReceiptClockIcon'

function PendingPayment({ timer, handleCheckPayment, order }) {

  return (
    <section>
      <div className='max-w-6xl mx-auto px-4 py-16 sm:py-32 space-y-8'>
        <div className='flex flex-col items-center space-y-4'>
          <ReceiptClockIcon className="h-10 w-10 fill-black" />
          <p className='text-xl sm:text-2xl font-bold text-center'>Complete your payment soon</p>
        </div>
        <div className='p-4 rounded-xl flex flex-col items-center'>
          <span className='font-bold text-sm sm:text-base'>Please complete payment before:</span>
          <p><span className='font-bold'>{timer?.hours}</span> hrs : <span className='font-bold'>{timer?.minutes}</span> mins : <span className='font-bold'>{timer?.seconds}</span> secs</p>
        </div>
        <p className='text-xs sm:text-sm text-center sm:mx-16'>
          Your transaction will be processed once the payment is verified
        </p>

        <div className='flex flex-col items-center w-full space-y-2'>
          <button
            onClick={handleCheckPayment}
            className={
              `
              bg-black
              px-5
              py-2 
              text-white 
              rounded-md 
              text-sm 
              md:text-base
            `
            }
            disabled={!order?.mpayment_link}
          >
            Check payment status
          </button>
        </div>
      </div>
    </section>
  )

}

export default PendingPayment