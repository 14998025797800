import React from 'react'

function RadioInput({
    name,
    label,
    options,
    styled,
    disabled,
    onChange,
    onBlur,
    error,
    value,
}) {
    return (
        <div className='space-y-2'>
            {label && (
                <p className='font-bold text-sm sm:text-base'>{label}</p>
            )}
            <div className={`${styled ? "space-y-4" : "flex space-x-6"}`}>
                {options?.map((option, i) => (
                    styled ? (
                        <label key={i} className='flex justify-between border border-black bg-app-light-grey p-4 rounded-md text-sm sm:text-base' htmlFor={`${name}${option.name}`}>
                            <div>
                                <p className='font-bold'>{option.text}</p>
                                <p className=''>{option.description}</p>
                            </div>
                            <input
                                type="radio"
                                id={`${name}${option.name}`}
                                name={name}
                                disabled={disabled}
                                onChange={onChange}
                                onBlur={onBlur}
                                value={option?.id}
                                checked={option?.id?.toString() === value?.toString()}
                            />
                        </label>
                    ) : (
                        <div className='space-x-2 text-sm sm:text-base'>
                            <input
                                type="radio"
                                id={`${name}${option.name}`}
                                name={name}
                                disabled={disabled}
                                onChange={onChange}
                                onBlur={onBlur}
                                value={option?.id}
                                checked={option?.id?.toString() === value?.toString()}
                            />
                            <label htmlFor={`${name}${option.name}`}>{option.text}</label>
                        </div>
                    )
                ))}
            </div>
            {error && (
                <p className='text-xs text-app-brown'>{error}</p>
            )}
        </div>
    )
}

export default RadioInput