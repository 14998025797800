import React from 'react'

function NaturalIcon({
    className = "h-12 sm:h-16 w-12 sm:w-16 stroke-black"
}) {
    return (
        <svg className={className} viewBox="0 0 80 80" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M73.0047 25.6C67.4481 12.8848 54.7599 4 39.9999 4C25.2381 4 12.5499 12.8848 6.99512 25.6" stroke-width="7.2" stroke-linecap="round" stroke-linejoin="round" />
            <path d="M40 22C36.5756 25.3481 33.8559 29.3476 32.0011 33.763C30.1463 38.1783 29.1939 42.9203 29.2 47.7094C29.2 48.6094 29.2324 49.5022 29.299 50.3878C32.6939 53.7326 35.3886 57.72 37.2259 62.1174C39.0632 66.5149 40.0062 71.2342 40 76C39.9935 71.2339 40.9364 66.5142 42.7737 62.1165C44.611 57.7187 47.3058 53.731 50.701 50.386C51.0979 45.1602 50.3456 39.9108 48.4969 35.0069C46.6481 30.1029 43.7479 25.6633 40 22Z" stroke-width="7.2" stroke-linecap="round" stroke-linejoin="round" />
            <path d="M4 40C4 59.8828 20.1172 76 40 76C40.0065 71.2339 39.0636 66.5143 37.2263 62.1165C35.389 57.7187 32.6941 53.731 29.299 50.386C22.5675 43.7204 13.4733 39.9869 4 40Z" stroke-width="7.2" stroke-linecap="round" stroke-linejoin="round" />
            <path d="M76 40C76 59.8828 59.8828 76 40 76C39.9935 71.2339 40.9365 66.5143 42.7737 62.1165C44.611 57.7187 47.3059 53.731 50.701 50.386C57.4325 43.7204 66.5267 39.9869 76 40Z" stroke-width="7.2" stroke-linecap="round" stroke-linejoin="round" />
        </svg>
    )
}

export default NaturalIcon