import { ConfirmationModule } from 'modules/booking-module-client';
import React from 'react'
import { Navigate, useNavigate } from 'react-router-dom';

function PaymentFailed() {
    const navigate = useNavigate();
    const urlParams = new URLSearchParams(window.location.search);
    const orderId = urlParams.get('order_id');
    const transactionStatus = urlParams.get('transaction_status');

    if (!orderId || !transactionStatus) {
        return (
            <Navigate to="/" />
        )
    }
    return (
        <div className='app-main-container max-w-xl mx-auto flex-1 flex items-center'>
            <ConfirmationModule
                type="failed"
                bookingConfirmation={{
                    number: orderId,
                    description: "Thank you for choosing us. We look forward to serving you and providing a memorable experience."
                }}
            />
        </div>
    )
}

export default PaymentFailed