export const timer = (beforeFunction, afterFunction, timer) => {
  beforeFunction();
  const interval = setInterval(() => {
    afterFunction();
  }, timer);
  return () => clearInterval(interval);
};

export const validateEmail = (email) => {
  const validEmailRegex =
    /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9.-]+\.[a-zA-Z0-9-]{2,6}$/;
  if (email.match(validEmailRegex)) {
    return true;
  } else {
    return false;
  }
};

export const validatePassword = (password) => {
  const passwordRegex =
    /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)[a-zA-Z\d!@#$%^&*()_+={}\[\]|\\:;"'<>,.?/~`-]{8,}$/;
  if (passwordRegex.test(password)) {
    return true;
  } else {
    return false;
  }
};