import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'

// Images & Icons
import logo from 'assets/images/logo.png'
import SearchIcon from 'assets/icons/general/SearchIcon'
import AvatarIcon from 'assets/icons/general/AvatarIcon'

// Apis
import useFetch from 'utils/hooks/useFetch';
import {
  GET_ALL_SERVICES,
  LOGOUT_USER
} from 'api';

// Cookies
import Cookies from "js-cookie";

function Navbar({
  openMenu,
  setOpenLoginUser
}) {
  const dispatch = useDispatch();

  const { data: _services, loading: loadingServices, fetch: getAllServices } = useFetch(GET_ALL_SERVICES)
  const { data: logoutData, loading: loadingLogout, fetch: logoutUser } = useFetch(LOGOUT_USER)

  const userDetail = useSelector((state) => state.userDetail);
  const services = _services?.filter(service => service?.is_active).map(service => {
    return { id: service.id, title: service.title, description: service.description }
  })
  const headerSetting = useSelector((state) => state.headerSetting)
  const mainSiteSetting = useSelector((state) => state.mainSiteSetting)
  const homeSetting = useSelector((state) => state.homeSetting)
  const aboutUsSetting = useSelector((state) => state.aboutUsSetting)
  const contactUsSetting = useSelector((state) => state.contactUsSetting)
  const ourServicesSetting = useSelector((state) => state.ourServicesSetting)

  const [isOpenFloatingAccountMenu, setIsOpenFloatingAccountMenu] = useState(false);
  const [scrolled, setScrolled] = useState(false)
  const [showSearchBar, setShowSearchBar] = useState(false)
  const [searchQuery, setSearchQuery] = useState("")

  const mainSiteLogo = mainSiteSetting && mainSiteSetting.settings && mainSiteSetting.settings.filter(item => item?.component_name === 'site-logo')[0].content_file_url
  const actionButtonText = headerSetting && headerSetting.settings && headerSetting.settings.filter(item => item?.component_name === 'action-button-text')[0].content_text
  const signInButtonText = headerSetting && headerSetting.settings && headerSetting.settings.filter(item => item?.component_name === 'sign-in-button-text')[0].content_text
  const homeDisplayName = homeSetting && homeSetting.settings && homeSetting.settings.filter(item => item?.component_name === 'display-name')[0].content_text
  const aboutUsDisplayName = aboutUsSetting && aboutUsSetting.settings && aboutUsSetting.settings.filter(item => item?.component_name === 'display-name')[0].content_text
  const contactUsDisplayName = contactUsSetting && contactUsSetting.settings && contactUsSetting.settings.filter(item => item?.component_name === 'display-name')[0].content_text
  const ourServicesDisplayName = ourServicesSetting && ourServicesSetting.settings && ourServicesSetting.settings.filter(item => item?.component_name === 'display-name')[0].content_text

  const pathName = window.location.pathname
  const searchResults = services?.filter(item => item.title.toLowerCase().includes(searchQuery))

  const handleChangeSearch = (e) => {
    setSearchQuery(e.target.value)
  }

  const handleOnClickSignOut = async () => {
    try {
      const result = await logoutUser();
      if (
        result &&
        result &&
        result.message &&
        result.message === "User logged out"
      ) {
        setIsOpenFloatingAccountMenu(false);
        Cookies.remove("ut");
        dispatch({ type: "SET_USER_DETAIL", userDetail: {} });
        window.location.assign("/");
      }
    } catch (err) {
      console.log(err.response);
    }
  };

  const handleOnClickAvatar = () => {
    if (userDetail && userDetail.id) {
      setIsOpenFloatingAccountMenu(!isOpenFloatingAccountMenu)
    } else {
      setOpenLoginUser(true)
    }
  }

  const handleOnClickMyProfile = () => {
    setIsOpenFloatingAccountMenu(false);
    window.location.assign("/user/profiles");
  }

  useEffect(() => {
    window.onscroll = function () {
      if (window.scrollY > 50) {
        setScrolled(true);
      } else {
        setScrolled(false);
      }
    };
  }, []);

  useEffect(() => {
    getAllServices()
  }, [getAllServices])

  return (
    <div className={`${pathName === "/" ? `fixed top-0 left-0 right-0 ${scrolled ? "bg-black shadow-md" : "bg-transparent"}` : "sticky top-0 bg-black shadow-md"} transition-all z-40`}>
      <div className={`app-nav-container flex flex-row justify-between items-center gap-6 md:text-xs lg:text-base`}>
        <div>
          <a href='/'>
            <img src={mainSiteLogo && mainSiteLogo !== '' ? mainSiteLogo : logo} alt="logo" className='h-6 md:h-8 lg:h-12 object-contain' />
          </a>
        </div>
        <div className={`hidden md:flex md:space-x-6 lg:space-x-8 ${pathName === "/" ? `${scrolled ? "text-white" : "text-black"}` : "text-white"}`}>
          <ul className='flex items-center space-x-2 lg:space-x-4'>
            <li>
              <a href="/">{homeDisplayName && homeDisplayName !== '' ? homeDisplayName : 'Home'}</a>
            </li>
            <li>
              <a href="/about">{aboutUsDisplayName && aboutUsDisplayName !== '' ? aboutUsDisplayName : 'About us'}</a>
            </li>
            <li>
              <a href="/services">{ourServicesDisplayName && ourServicesDisplayName !== '' ? ourServicesDisplayName : 'Our services'}</a>
            </li>
            <li>
              <a href="/contact-us">{contactUsDisplayName && contactUsDisplayName !== '' ? contactUsDisplayName : 'Contact us'}</a>
            </li>
          </ul>
          <div className='flex space-x-2 lg:space-x-4'>
            <button onClick={() => setShowSearchBar(prev => !prev)}>
              <SearchIcon className={`md:h-5 md:w-5 lg:h-6 lg:w-6 ${pathName === "/" ? `${scrolled ? "fill-white" : "fill-black"}` : "fill-white"}`} />
            </button>
            <a href="/booking/now" className={`rounded-lg text-center md:w-24 lg:w-32 p-2 font-bold ${pathName === "/" ? `${scrolled ? "bg-white text-black" : "bg-black text-white"}` : "bg-white text-black"}`}>
              {actionButtonText && actionButtonText !== '' ? actionButtonText : 'Book now'}
            </a>
            {/* <div className="flex flex-row items-center relative">
              <div>
                <button
                  type="button"
                  className="flex flex-row w-full items-center gap-2 font-semibold"
                  onClick={() => handleOnClickAvatar()}
                >
                  <AvatarIcon className={`${pathName === "/" && scrolled ? "fill-white" : pathName !== "/" ? "fill-white" : "fill-black"} h-6 w-6`} />
                  <p>{userDetail && userDetail.username ? userDetail.username : ""}</p>
                </button>
              </div>
              {isOpenFloatingAccountMenu && (
                <div className="absolute right-0 top-12 z-10 w-auto rounded-md bg-white shadow-md" >
                  <div className="bg-app-cream rounded-md" role="none">
                    <button type="button" onClick={() => handleOnClickMyProfile()} className="flex flex-col w-full rounded-t-md px-6 py-3 text-left hover:bg-gray-200" id="menu-item-3">
                      My Profile
                      <span className="overflow-hidden ellipsis text-xs lg:text-sm text-gray-500">
                        {userDetail && userDetail.email}
                      </span>
                    </button>
                    <button type="button" onClick={() => handleOnClickSignOut()} className="w-full rounded-md px-6 py-3 text-left hover:bg-gray-200" id="menu-item-3">
                      Sign Out
                    </button>
                  </div>
                </div>
              )}
            </div> */}
          </div>
        </div>
        <button
          className='text-white text-sm md:hidden active:border-b-2 active:border-white transition-all font-medium'
          onClick={openMenu}
        >
          Menu
        </button>
      </div>

      {showSearchBar && (
        <div className={`hidden sm:block absolute left-0 right-0  bg-app-light-grey-4 shadow-md`}>
          <div className='app-nav-container space-y-8'>
            <div className='flex items-center border border-black bg-white drop-shadow-lg rounded-lg px-3 py-2 gap-4'>
              <input
                className='flex-1 bg-transparent outline-none text-xs sm:text-sm'
                placeholder='What are you looking for?'
                value={searchQuery}
                onChange={handleChangeSearch}
                autoFocus
              />
              <button>
                <SearchIcon className="h-3 sm:h-5 w-3 sm:w-5 fill-black" />
              </button>
            </div>
            {searchQuery ? (
              <div className='flex flex-col gap-6'>
                {searchResults.map((item, i) => (
                  <a href={`/services/${item.id}`} className='space-y-2'>
                    <p className='text-xs sm:text-sm font-bold'>{item.title}</p>
                    <p className='text-xs sm:text-sm'>{item.description}</p>
                  </a>
                ))}

              </div>
            ) : (
              <div className='text-xs sm:text-sm h-20 flex items-center justify-center text-app-light-grey-2'>
                No recent searches
              </div>
            )}
          </div>
        </div>
      )}
    </div>

  )
}

export default Navbar