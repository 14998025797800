import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from "react-redux";

// Components
import Navbar from './Navbar';
import Footer from './Footer';
import Menu from 'components/menu/Menu';
import Login from 'components/user_authentication/Login';
import Register from 'components/user_authentication/Register';
import ForgotPassword from 'components/user_authentication/ForgotPassword';
// import ResetPassword from 'components/user_authentication/ResetPassword';
// import VerifyUserAccount from 'components/user_authentication/VerifyUserAccount';
import SupportChat from 'components/support_chat/SupportChat';
import CookieConsent from 'components/modal/CookieConsent';

// Apis
import useFetch from 'utils/hooks/useFetch';
import {
    GET_GUEST_TOKEN,
    GET_SITE_MANAGER_SETTINGS,
    GET_GENERAL_SETTINGS,
    GET_CURRENCIES,
    GET_USER_PROFILE
} from 'api';

// Utils & Helpers
import useAnimation from 'utils/hooks/useAnimation';

// Cookies
import Cookies from 'js-cookie';

function MainContainer({ children }) {
    useAnimation();
    const dispatch = useDispatch();

    const mainSiteSetting = useSelector((state) => state.mainSiteSetting)
    const userDetail = useSelector((state) => state.userDetail);

    const { fetch: getGuestToken } = useFetch(GET_GUEST_TOKEN)
    const { data: _userProfile, loading: loadingUserProfile, fetch: getUserProfile } = useFetch(GET_USER_PROFILE)
    const { data: _siteManagerSettings, loading: loadingSiteManagerSettings, fetch: getSiteManagerSettings } = useFetch(GET_SITE_MANAGER_SETTINGS)
    const { data: _generalSettings, loading: loadingGeneralSettings, fetch: getGeneralSettings } = useFetch(GET_GENERAL_SETTINGS)
    const { data: _currencies, loading: loadingCurrency, fetch: getCurrency } = useFetch(GET_CURRENCIES)

    const [isMenuOpen, setMenuOpen] = useState(false)
    const [isOpenCookieConsent, setOpenCookieConsent] = useState(true);
    const [isOpenLoginUser, setOpenLoginUser] = useState(false);
    const [isOpenRegisterUser, setOpenRegisterUser] = useState(false);
    const [isOpenForgotPassword, setOpenForgotPassword] = useState(false);

    useEffect(() => {
        getSiteManagerSettings()
        getGeneralSettings()
        getCurrency()
    }, [
        getSiteManagerSettings,
        getGeneralSettings,
        getCurrency
    ]);

    useEffect(() => {
        if (!Cookies.get("gt")) {
            getGuestToken()
        }
    }, [getGuestToken])

    useEffect(() => {
        if (Cookies.get("ut")) {
            getUserProfile()
        }
    }, [getUserProfile])

    useEffect(() => {
        if (_siteManagerSettings) {
            dispatch({ type: "SET_MAIN_SITE_SETTING", mainSiteSetting: _siteManagerSettings?.filter((item) => { return item?.section_name === 'main-site' })[0] });
            dispatch({ type: "SET_HEADER_SETTING", headerSetting: _siteManagerSettings?.filter((item) => { return item?.section_name === 'header' })[0] });
            dispatch({ type: "SET_FOOTER_SETTING", footerSetting: _siteManagerSettings?.filter((item) => { return item?.section_name === 'footer' })[0] });
            dispatch({ type: "SET_NEWSLETTER_SETTING", newsletterSetting: _siteManagerSettings?.filter((item) => { return item?.section_name === 'newsletter' })[0] });
            dispatch({ type: "SET_TERMS_CONDITIONS_SETTING", termsConditionsSetting: _siteManagerSettings?.filter((item) => { return item?.section_name === 'terms-and-conditions' })[0] });
            dispatch({ type: "SET_HOME_SETTING", homeSetting: _siteManagerSettings?.filter(item => item?.section_name === 'home')[0] });
            dispatch({ type: "SET_ABOUT_US_SETTING", aboutUsSetting: _siteManagerSettings?.filter(item => item?.section_name === 'about-us')[0] });
            dispatch({ type: "SET_CONTACT_US_SETTING", contactUsSetting: _siteManagerSettings?.filter(item => item?.section_name === 'contact-us')[0] });
            dispatch({ type: "SET_OUR_SERVICES_SETTING", ourServicesSetting: _siteManagerSettings?.filter(item => item?.section_name === 'our-services')[0] });
            dispatch({ type: "SET_FAQ_SETTING", FAQSetting: _siteManagerSettings?.filter(item => item?.section_name === 'frequently-asked-questions')[0] });
        }
    }, [_siteManagerSettings]);

    useEffect(() => {
        if (_generalSettings) {
            dispatch({ type: "SET_GENERAL_SETTING", generalSetting: _generalSettings[0] })
        }
    }, [_generalSettings]);

    useEffect(() => {
        if (_currencies) {
            dispatch({ type: "SET_CURRENCIES", currencies: _currencies });
        }
    }, [_currencies]);

    useEffect(() => {
        if (_userProfile) {
            dispatch({ type: "SET_USER_DETAIL", userDetail: _userProfile });
        }
    }, [_userProfile]);

    useEffect(() => {
        const siteTitle = mainSiteSetting && mainSiteSetting.settings && mainSiteSetting.settings.filter(item => item?.component_name === 'site-title')[0].content_text
        const tabTitle = mainSiteSetting && mainSiteSetting.settings && mainSiteSetting.settings.filter(item => item?.component_name === 'tab-title')[0].content_text
        const mainTabIcon = mainSiteSetting && mainSiteSetting.settings && mainSiteSetting.settings.filter(item => item?.component_name === 'tab-icon')[0].content_file_url

        document.title = siteTitle && siteTitle !== '' ? siteTitle : 'React App';
        document.head.title = tabTitle && tabTitle !== '' ? tabTitle : 'React App';
        document.head.children['1'].href = mainTabIcon && mainTabIcon !== '' ? mainTabIcon : '';
    }, [mainSiteSetting]);

    return (
        <div className='min-h-screen flex flex-col'>
            <Navbar
                openMenu={() => setMenuOpen(true)}
                setOpenLoginUser={setOpenLoginUser}
            />
            {children}
            <Footer />
            <Menu
                isOpen={isMenuOpen}
                setMenuOpen={setMenuOpen}
                setOpenLoginUser={setOpenLoginUser}
                userDetail={userDetail}
            />
            {/* <SupportChat /> */}
            {isOpenLoginUser && (
                <Login
                    setOpenLoginUser={setOpenLoginUser}
                    setOpenRegisterUser={setOpenRegisterUser}
                    setOpenForgotPassword={setOpenForgotPassword}
                />
            )}
            {isOpenRegisterUser && (
                <Register
                    setOpenLoginUser={setOpenLoginUser}
                    setOpenRegisterUser={setOpenRegisterUser}
                />
            )}
            {isOpenForgotPassword && (
                <ForgotPassword
                    setOpenForgotPassword={setOpenForgotPassword}
                />
            )}
            {isOpenCookieConsent && window.location.pathname !== "/privacy-policy" && Cookies.get('cookie_consent') !== "true" && (
                <CookieConsent setOpenCookieConsent={setOpenCookieConsent} />
            )}
        </div>
    )
}

export default MainContainer