import React, { useState } from "react";
import { useDispatch } from "react-redux";

// Icons and Images
import EmailLogin from "assets/icons/user_authentication/EmailLogin.png";
import CloseFullscreenBtn from "assets/icons/general/CloseFullscreenBtn.png";

// Components
import { InputText } from "components/input/Inputs";

// Apis
import useFetch from "utils/hooks/useFetch";
import { REQUEST_FORGOT_PASSWORD } from "api";

// Helpers
import { validateEmail } from "utils/helpers";

const ForgotPassword = ({
  setOpenForgotPassword
}) => {
  const dispatch = useDispatch();
  const { data: forgotPasswordData, loading: loadingForgotPassword, fetch: requestForgotPassword } = useFetch(REQUEST_FORGOT_PASSWORD)

  const [emailForgotPassword, setEmailForgotPassword] = useState("");
  const [invalidEmail, setInvalidEmail] = useState(false);
  const [invalidEmailFormat, setInvalidEmailFormat] = useState(false);
  const [notValidUser, setNotValidUser] = useState(false);

  const handleValidation = () => {
    let invalidEmail = false;
    let invalidEmailFormat = false;

    if (!emailForgotPassword) {
      invalidEmail = true;
    } else {
      if (validateEmail(emailForgotPassword) !== true) {
        invalidEmailFormat = true;
      }
    }

    setInvalidEmail(invalidEmail);
    setInvalidEmailFormat(invalidEmailFormat);

    if (invalidEmail !== true && invalidEmailFormat !== true) {
      setNotValidUser(false);
      return true;
    } else {
      return false;
    }
  };

  const handleOnClickBtnSubmit = async () => {
    dispatch({
      type: "SET_IS_LOADING",
      isLoading: true,
    });
    try {
      const isValid = handleValidation();
      if (isValid) {
        const result = await requestForgotPassword({
          params: {
            email: emailForgotPassword
          }
        });
        if (result && result.success) {
          setEmailForgotPassword("");
          setOpenForgotPassword(false);
        }
      }
    } catch (err) {
      if (err && err.response && err.response.data) {
        const { response: { data: { message } = {} } = {} } = err || {};
        if (message === "user-not-found") {
          setNotValidUser(true);
        }
      }
    }
    dispatch({
      type: "SET_IS_LOADING",
      isLoading: false,
    });
  };

  return (
    <div className="flex justify-center items-center fixed text-left top-0 left-0 right-0 bottom-0 z-[999] bg-black/50">
      <div className="w-3/4 lg:w-1/2 px-6 lg:px-8 py-8 lg:py-10 flex flex-col justify-center items-center bg-app-cream rounded-3xl shadow-2xl">
        <div
          className="w-full flex justify-end mr-6 mb-4 lg:mr-8"
          onClick={() => setOpenForgotPassword(false)}
        >
          <img
            className="w-4 h-4 sm:w-5 sm:h-5 lg:w-7 lg:h-7 cursor-pointer"
            src={CloseFullscreenBtn}
            alt="close-fullscreen-btn"
          />
        </div>
        <div className="text-center font-bold text-2xl md:text-4xl">Forgot password</div>
        <div className="text-center text-base md:text-base font-semibold my-2">
          Please enter your email address below to receive your password reset instructions
        </div>
        <div className="w-3/4 md:w-3/5 flex  flex-col my-2">
          <div className="flex items-center gap-2 mb-2">
            <img src={EmailLogin} alt="email-icon" />
            <div className="text-sm md:text-base">Email</div>
          </div>
          <InputText
            className="text-sm md:text-base"
            border={"1px solid #000000"}
            borderRadius={"10px"}
            height={"5vh"}
            placeholder={"Fill in email address"}
            name={"loginEmailAddress"}
            value={emailForgotPassword}
            setter={setEmailForgotPassword}
          />
          {invalidEmail || invalidEmailFormat || notValidUser ? (
            <div className="text-red-500 mt-2">
              {invalidEmail
                ? "This field is required"
                : notValidUser
                  ? "User's not valid"
                  : "Please input a valid email format"}
            </div>
          ) : null}
        </div>
        <button
          className="w-3/4 md:w-3/5 flex items-center justify-center rounded-full text-white cursor-pointer py-2 mt-2 lg:mt-4 bg-black disabled:opacity-30"
          disabled={!emailForgotPassword}
          onClick={() => handleOnClickBtnSubmit()}
        >
          <div>Submit</div>
        </button>
      </div>
    </div>
  );
};

export default ForgotPassword;
