import React from 'react'

function TelephoneIcon({
    className = "h-6 w-6 fill-black"
}) {
    return (
        <svg className={className} viewBox="0 0 27 27" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M19.6675 27C20.026 27.002 20.3813 26.9327 20.7127 26.7959C21.0441 26.6592 21.345 26.4578 21.5977 26.2035L25.2559 22.5454C25.5073 22.2925 25.6484 21.9504 25.6484 21.5938C25.6484 21.2372 25.5073 20.895 25.2559 20.6421L19.8564 15.2427C19.6035 14.9913 19.2614 14.8502 18.9048 14.8502C18.5482 14.8502 18.206 14.9913 17.9531 15.2427L15.7934 17.389C14.2981 16.9903 12.9132 16.2563 11.7438 15.2427C10.7329 14.0714 9.99928 12.6872 9.59752 11.1931L11.7438 9.03337C11.9952 8.78045 12.1363 8.43833 12.1363 8.08172C12.1363 7.72511 11.9952 7.38298 11.7438 7.13007L6.34437 1.73064C6.09146 1.47923 5.74933 1.33812 5.39272 1.33812C5.03611 1.33812 4.69398 1.47923 4.44107 1.73064L0.79646 5.40225C0.54219 5.655 0.34081 5.95587 0.204071 6.28728C0.0673321 6.6187 -0.0020258 6.97404 4.50403e-05 7.33255C0.122508 12.5191 2.19501 17.469 5.80443 21.1956C9.531 24.805 14.4809 26.8775 19.6675 27ZM5.39947 4.60584L8.8956 8.10197L7.15428 9.84328C6.98936 9.99777 6.86586 10.1912 6.7951 10.4058C6.72435 10.6204 6.70861 10.8494 6.74933 11.0716C7.25386 13.3264 8.32206 15.4164 9.854 17.146C11.5823 18.6799 13.6728 19.7484 15.9284 20.2507C16.1473 20.2964 16.3741 20.2871 16.5885 20.2235C16.8029 20.1599 16.9982 20.044 17.1567 19.8862L18.898 18.1044L22.3942 21.6005L19.6945 24.3002C15.218 24.185 10.9467 22.3985 7.72122 19.2923C4.60699 16.0655 2.81532 11.7886 2.69976 7.30555L5.39947 4.60584ZM24.2975 12.1515H26.9972C27.0322 10.5466 26.7419 8.9511 26.1437 7.46133C25.5456 5.97156 24.652 4.61832 23.5168 3.48316C22.3817 2.34799 21.0284 1.45442 19.5387 0.856278C18.0489 0.258139 16.4534 -0.0321761 14.8485 0.00282745V2.70254C16.101 2.65921 17.3491 2.87399 18.5151 3.33351C19.6811 3.79303 20.7401 4.48748 21.6263 5.37369C22.5125 6.2599 23.207 7.31892 23.6665 8.48493C24.126 9.65093 24.3408 10.899 24.2975 12.1515Z" />
            <path d="M14.8484 8.10193C17.6831 8.10193 18.898 9.31681 18.898 12.1515H21.5977C21.5977 7.80497 19.1949 5.40222 14.8484 5.40222V8.10193Z" />
        </svg>
    )
}

export default TelephoneIcon