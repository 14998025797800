import React from 'react'
import { useSelector } from 'react-redux'
import logo from 'assets/images/logo.png'
import TwitterIcon from 'assets/icons/social_media/TwitterIcon'
import FacebookIcon from 'assets/icons/social_media/FacebookIcon'
import InstagramIcon from 'assets/icons/social_media/InstagramIcon'
import LinkedinIcon from 'assets/icons/social_media/LinkedinIcon'
import YoutubeIcon from 'assets/icons/social_media/YoutubeIcon'

function Footer() {
    const mainSiteSetting = useSelector((state) => state.mainSiteSetting)
    const footerSetting = useSelector((state) => state.footerSetting)
    const aboutUsSetting = useSelector((state) => state.aboutUsSetting)
    const contactUsSetting = useSelector((state) => state.contactUsSetting)
    const ourServicesSetting = useSelector((state) => state.ourServicesSetting)
    const FAQSetting = useSelector((state) => state.FAQSetting)

    const aboutUsDisplayName = aboutUsSetting && aboutUsSetting.settings && aboutUsSetting.settings.filter(item => item?.component_name === 'display-name')[0].content_text
    const contactUsDisplayName = contactUsSetting && contactUsSetting.settings && contactUsSetting.settings.filter(item => item?.component_name === 'display-name')[0].content_text
    const ourServicesDisplayName = ourServicesSetting && ourServicesSetting.settings && ourServicesSetting.settings.filter(item => item?.component_name === 'display-name')[0].content_text
    const faqDisplayName = FAQSetting && FAQSetting.settings && FAQSetting.settings.filter(item => item?.component_name === 'display-name')[0].content_text
    const mainSiteLogo = mainSiteSetting && mainSiteSetting.settings && mainSiteSetting.settings.filter(item => item?.component_name === 'site-logo')[0].content_file_url
    const copyrightText = footerSetting && footerSetting.settings && footerSetting.settings.filter(item => item?.component_name === 'copyright-text')[0].content_text
    const youtubeLink = footerSetting && footerSetting.settings && footerSetting.settings.filter(item => item?.component_name === 'youtube-link')[0].content_text
    const facebookLink = footerSetting && footerSetting.settings && footerSetting.settings.filter(item => item?.component_name === 'facebook-link')[0].content_text
    const twitterLink = footerSetting && footerSetting.settings && footerSetting.settings.filter(item => item?.component_name === 'twitter-link')[0].content_text
    const linkedinLink = footerSetting && footerSetting.settings && footerSetting.settings.filter(item => item?.component_name === 'linkedin-link')[0].content_text
    const instagramLink = footerSetting && footerSetting.settings && footerSetting.settings.filter(item => item?.component_name === 'instagram-link')[0].content_text

    return (
        <div className='bg-black text-white'>
            <div className='app-nav-container flex flex-col sm:flex-row justify-between gap-6 sm:gap-0'>
                <div className='grid grid-cols-1 sm:grid-cols-3 gap-6 sm:gap-12 text-sm'>
                    <ul className='space-y-2'>
                        <li>
                            <a href="/about">{aboutUsDisplayName && aboutUsDisplayName !== '' ? aboutUsDisplayName : 'About us'}</a>
                        </li>
                        <li>
                            <a href="/services">{ourServicesDisplayName && ourServicesDisplayName !== '' ? ourServicesDisplayName : 'Our services'}</a>
                        </li>
                        <li>
                            <a href="/faq">{faqDisplayName && faqDisplayName !== '' ? faqDisplayName : 'FAQ'}</a>
                        </li>
                        <li>
                            <a href="/contact-us">{contactUsDisplayName && contactUsDisplayName !== '' ? contactUsDisplayName : 'Contact us'}</a>
                        </li>
                    </ul>
                </div>
                <div className='space-y-2'>
                    <p className='font-bold'>Follow us</p>
                    <div className='flex gap-4'>
                        <a href={twitterLink && twitterLink !== '' ? twitterLink : '/'} target="_blank" rel="noopener noreferrer">
                            <TwitterIcon className="fill-white h-4 sm:h-5 w-4 sm:w-6" />
                        </a>
                        <a href={facebookLink && facebookLink !== '' ? facebookLink : '/'} target="_blank" rel="noopener noreferrer">
                            <FacebookIcon className="fill-white h-4 sm:h-5 w-4 sm:w-6" />
                        </a>
                        <a href={instagramLink && instagramLink !== '' ? instagramLink : '/'} target="_blank" rel="noopener noreferrer">
                            <InstagramIcon className="fill-white h-4 sm:h-5 w-4 sm:w-6" />
                        </a>
                        <a href={linkedinLink && linkedinLink !== '' ? linkedinLink : '/'} target="_blank" rel="noopener noreferrer">
                            <LinkedinIcon className="fill-white h-4 sm:h-5 w-4 sm:w-6" />
                        </a>
                        <a href={youtubeLink && youtubeLink !== '' ? youtubeLink : '/'} target="_blank" rel="noopener noreferrer">
                            <YoutubeIcon className="fill-white h-4 sm:h-5 w-4 sm:w-6" />
                        </a>
                    </div>
                </div>
            </div>
            <div className='app-nav-container flex flex-col sm:flex-row justify-between items-center sm:items-end gap-6 sm:gap-0'>
                <div>
                    <img src={mainSiteLogo && mainSiteLogo !== '' ? mainSiteLogo : logo} alt="logo" className='h-8 sm:h-12' />
                </div>
                <div className='text-[10px] sm:text-sm'>
                    <p>{copyrightText && copyrightText !== '' ? copyrightText : '2023 creme and coral organics. All rights reserved.'}</p>
                </div>
            </div>
        </div>
    )
}

export default Footer