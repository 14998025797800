export const treatments = [
    {
        id: "123456879",
        title: "Signature creme and coral spa",
        description: "Lorem ipsum dolor sit amet consectetur. Netus auctor sit dictum nec. Ut at nulla ornare facilisi consectetur. Commodo felis sed vestibulum et. Arcu nibh vulputate odio arcu et. Metus nisi aliquam enim justo. Platea blandit at diam montes purus enim pellentesque quis sagittis. Elit sed tellus bibendum lectus. Risus enim pretium ut convallis. Euismod tempus vitae proin duis."
    },
    {
        id: "321654987",
        title: "Pure bliss spa",
        description: "Lorem ipsum dolor sit amet consectetur. Netus auctor sit dictum nec. Ut at nulla ornare facilisi consectetur. Commodo felis sed vestibulum et. Arcu nibh vulputate odio arcu et. Metus nisi aliquam enim justo. Platea blandit at diam montes purus enim pellentesque quis sagittis. Elit sed tellus bibendum lectus. Risus enim pretium ut convallis. Euismod tempus vitae proin duis."
    },
    {
        id: "456987321",
        title: "Relax and wind",
        description: "Lorem ipsum dolor sit amet consectetur. Netus auctor sit dictum nec. Ut at nulla ornare facilisi consectetur. Commodo felis sed vestibulum et. Arcu nibh vulputate odio arcu et. Metus nisi aliquam enim justo. Platea blandit at diam montes purus enim pellentesque quis sagittis. Elit sed tellus bibendum lectus. Risus enim pretium ut convallis. Euismod tempus vitae proin duis."
    },
]

export const availableSlots = [
    {
        date: "2023-08-11",
        availableTime: [
            {
                time: "09.00"
            },
            {
                time: "12.00"
            },
            {
                time: "13.00"
            },
        ]
    },
    {
        date: "2023-09-11",
        availableTime: [
            {
                time: "09.00"
            },
            {
                time: "12.00"
            },
            {
                time: "13.00"
            },
        ]
    },
    {
        date: "2023-09-12",
        availableTime: [
            {
                time: "10.00"
            },
            {
                time: "11.00"
            },
            {
                time: "14.00"
            },
        ]
    },
]



export const preferredTimeOptions = [
    {
        id: "morning",
        text: "Morning"
    },
    {
        id: "afternoon",
        text: "Afternoon"
    },
    {
        id: "evening",
        text: "Evening"
    },
    // {
    //     id: "night",
    //     text: "Night"
    // },
]

export const titleOptions = [
    {
        name: "mr",
        id: "mr",
        text: "Mr.",
    },
    {
        name: "miss",
        id: "miss",
        text: "Miss",
    },
    {
        name: "mrs",
        id: "mrs",
        text: "Mrs.",
    },
]


export const durationOptions = [
    {
        value: "30",
        text: "30 mins",
        price: 150000
    },
    {
        value: "30",
        text: "45 mins",
        price: 200000
    },
    {
        value: "30",
        text: "60 mins",
        price: 250000
    },
]

export const bookingConfirmation = {
    number: "#1681910",
    description: (
        <>
            Lorem ipsum dolor sit amet consectetur. Netus auctor sit dictum nec. Ut at nulla ornare facilisi consectetur.<br /><br />
            Commodo felis sed vestibulum et. Arcu nibh vulputate odio arcu et. Metus nisi aliquam enim justo.
        </>
    )
}

export const faq = [
    {
        name: "Appointment",
        list: [
            {
                question: "Can I book my appointment 30 days in advance?",
                answer: "Lorem ipsum dolor sit amet consectetur. Netus auctor sit dictum nec. Ut at nulla ornare facilisi consectetur. Commodo felis sed vestibulum et. ",
            },
            {
                question: "Do you provide home service?",
                answer: "Lorem ipsum dolor sit amet consectetur. Netus auctor sit dictum nec. Ut at nulla ornare facilisi consectetur. Commodo felis sed vestibulum et. ",
            },
        ]
    },
    {
        name: "Products",
        list: [
            {
                question: "Can I book my appointment 30 days in advance?",
                answer: "Lorem ipsum dolor sit amet consectetur. Netus auctor sit dictum nec. Ut at nulla ornare facilisi consectetur. Commodo felis sed vestibulum et. ",
            },
            {
                question: "Do you provide home service?",
                answer: "Lorem ipsum dolor sit amet consectetur. Netus auctor sit dictum nec. Ut at nulla ornare facilisi consectetur. Commodo felis sed vestibulum et. ",
            },
            {
                question: "Do you provide home service?",
                answer: "Lorem ipsum dolor sit amet consectetur. Netus auctor sit dictum nec. Ut at nulla ornare facilisi consectetur. Commodo felis sed vestibulum et. ",
            },
        ]
    },
    {
        name: "Corporate sales",
        list: [
            {
                question: "Can I book my appointment 30 days in advance?",
                answer: "Lorem ipsum dolor sit amet consectetur. Netus auctor sit dictum nec. Ut at nulla ornare facilisi consectetur. Commodo felis sed vestibulum et. ",
            },
            {
                question: "Can I book my appointment 30 days in advance?",
                answer: "Lorem ipsum dolor sit amet consectetur. Netus auctor sit dictum nec. Ut at nulla ornare facilisi consectetur. Commodo felis sed vestibulum et. ",
            },
            {
                question: "Do you provide home service?",
                answer: "Lorem ipsum dolor sit amet consectetur. Netus auctor sit dictum nec. Ut at nulla ornare facilisi consectetur. Commodo felis sed vestibulum et. ",
            },
        ]
    },
    {
        name: "Join the community",
        list: [
            {
                question: "Can I book my appointment 30 days in advance?",
                answer: "Lorem ipsum dolor sit amet consectetur. Netus auctor sit dictum nec. Ut at nulla ornare facilisi consectetur. Commodo felis sed vestibulum et. ",
            },
        ]
    },
    {
        name: "Your account",
        list: [
            {
                question: "Can I book my appointment 30 days in advance?",
                answer: "Lorem ipsum dolor sit amet consectetur. Netus auctor sit dictum nec. Ut at nulla ornare facilisi consectetur. Commodo felis sed vestibulum et. ",
            },
            {
                question: "Can I book my appointment 30 days in advance?",
                answer: "Lorem ipsum dolor sit amet consectetur. Netus auctor sit dictum nec. Ut at nulla ornare facilisi consectetur. Commodo felis sed vestibulum et. ",
            },
        ]
    },
    {
        name: "How to get here",
        list: [
            {
                question: "Can I book my appointment 30 days in advance?",
                answer: "Lorem ipsum dolor sit amet consectetur. Netus auctor sit dictum nec. Ut at nulla ornare facilisi consectetur. Commodo felis sed vestibulum et. ",
            },
        ]
    },
]