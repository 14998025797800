import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { useDispatch } from "react-redux";

// Apis
import useFetch from "utils/hooks/useFetch";
import { VERIFY_USER_ACCOUNT } from "api";

const VerifyUserAccount = () => {
  const dispatch = useDispatch();
  const { userToken } = useParams();
  const { data: verifyUserData, loading: loadingVerifyUser, fetch: verifyUser } = useFetch(VERIFY_USER_ACCOUNT)

  const [isVerifiedSuccess, setVerifiedSuccess] = useState(false);
  const [verificationMessage, setVerificationMessage] = useState('');

  useEffect(() => {
    handleVerifyUserAccount(userToken);
  }, [userToken]);

  const handleVerifyUserAccount = async (token) => {
    try {
      const result = await verifyUser(({
        params: {
          token
        }
      }));
      if (result && result.id) {
        setVerifiedSuccess(true)
        setVerificationMessage("Your account has successfully been verified")
      }
    } catch (err) {
      if (err && err.response && err.response.data) {
        const { response: { data: { message, success } = {} } = {} } =
          err || {};
        let finalMsg = "";
        setVerifiedSuccess(false)
        if (message === "invalid-token") {
          finalMsg = "Invalid Token";
          setVerificationMessage(finalMsg)
        } else if (message === "user-verified-before") {
          finalMsg = "This account was verified before, please try to login.";
          setVerificationMessage(finalMsg)
        } else if (message === "error-verify-user") {
          finalMsg = "Verification Error"
          setVerificationMessage(finalMsg)
        }
      }
    }
  };

  return (
    <div className='flex-1 flex flex-col gap-4 p-4 items-center justify-center h-96'>
      <div className={`uppercase text-3xl sm:text-4xl font-bold ${isVerifiedSuccess ? 'text-green-500' : 'text-red-500'}`}>
        {isVerifiedSuccess ? 'Verification Success' : 'Verification Failed'}
      </div>
      <div className='text-sm sm:text-base mb-4'>
        {verificationMessage}
      </div>
      <a className='bg-black text-white px-3 py-2 text-xs sm:text-sm rounded-md' href="/">Back to Home</a>
    </div>
  );
};

export default VerifyUserAccount;
