import { createStore } from 'redux'

const initialState = {
  userDetail: {},
  generalSetting: {},
  mainSiteSetting: {},
  headerSetting: {},
  footerSetting: {},
  newsletterSetting: {},
  termsConditionsSetting: {},
  homeSetting: {},
  aboutUsSetting: {},
  contactUsSetting: {},
  ourServicesSetting: {},
  FAQSetting: {},
  currencies: []
}

const changeState = (state = initialState, { type, ...rest }) => {
  switch (type) {
    case 'SET_USER_DETAIL':
      return { ...state, ...rest }
    case 'SET_GENERAL_SETTING':
      return { ...state, ...rest }
    case 'SET_MAIN_SITE_SETTING':
      return { ...state, ...rest }
    case 'SET_HEADER_SETTING':
      return { ...state, ...rest }
    case 'SET_FOOTER_SETTING':
      return { ...state, ...rest }
    case 'SET_NEWSLETTER_SETTING':
      return { ...state, ...rest }
    case 'SET_TERMS_CONDITIONS_SETTING':
      return { ...state, ...rest }
    case 'SET_HOME_SETTING':
      return { ...state, ...rest }
    case 'SET_ABOUT_US_SETTING':
      return { ...state, ...rest }
    case 'SET_CONTACT_US_SETTING':
      return { ...state, ...rest }
    case 'SET_OUR_SERVICES_SETTING':
      return { ...state, ...rest }
    case 'SET_FAQ_SETTING':
      return { ...state, ...rest }
    case 'SET_CURRENCIES':
      return { ...state, ...rest }
    default:
      return state
  }
}

const store = createStore(changeState)
export default store
