import React from 'react'

function ReceiptClockIcon({
    className = "h-10 w-10 fill-app-blue"
}) {
    return (
        <svg width="46" height="48" viewBox="0 0 46 48" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M27.4764 33.6357V25.1867H30.911V31.6437L36.4978 34.8722L34.7806 37.8488L27.4764 33.6357ZM37.7801 3.43455L34.3455 0L30.911 3.43455L27.4764 0L24.0419 3.43455L20.6073 0L17.1728 3.43455L13.7382 0L10.3037 3.43455L6.8691 0L3.43455 3.43455L0 0V45.794L3.43455 42.3595L6.8691 45.794L10.3037 42.3595L13.7382 45.794L17.3559 42.1763C17.6765 42.6113 18.0428 43.0006 18.4321 43.3898C21.436 46.3489 25.4848 48.0052 29.7014 48C33.9179 47.9948 37.9626 46.3284 40.9592 43.362C43.9558 40.3955 45.6629 36.3678 45.7107 32.1516C45.7585 27.9353 44.1432 23.8699 41.2146 20.8363V0L37.7801 3.43455ZM18.5466 20.6073C17.1728 21.9124 16.0966 23.4694 15.2723 25.1867H6.8691V20.6073H18.5466ZM13.8985 29.7661C13.7382 30.5217 13.7382 31.2773 13.7382 32.0558C13.7382 32.8343 13.7382 33.5899 13.8985 34.3455H6.8691V29.7661H13.8985ZM34.3455 16.0279H6.8691V11.4485H34.3455V16.0279ZM40.8712 32.0558C40.8712 33.5212 40.5964 34.9637 40.0698 36.3147C39.4744 37.6427 38.6502 38.9249 37.6198 39.9095C36.6352 40.9399 35.353 41.7642 34.025 42.3595C32.674 42.8861 31.2315 43.1609 29.7661 43.1609C23.6297 43.1609 18.6611 38.1922 18.6611 32.0558C18.6611 29.1021 19.8288 26.3316 21.9124 24.2021C24.0419 22.1185 26.8124 20.9508 29.7661 20.9508C35.8796 20.9508 40.8712 25.9194 40.8712 32.0558Z" fill="black" />

        </svg>
    )
}

export default ReceiptClockIcon