import React, { useEffect } from 'react'
import { useSelector } from 'react-redux'

import ServiceCard from 'components/card/ServiceCard';

import useFetch from 'utils/hooks/useFetch';
import { GET_ALL_SERVICES } from 'api';

function OurServices() {
    const { data: _services, loading: loadingServices, fetch: getAllServices } = useFetch(GET_ALL_SERVICES)
    const services = _services?.filter(service => service?.is_active)
    const ourServicesSetting = useSelector((state) => state.ourServicesSetting)

    const ourServicesHeadline = ourServicesSetting && ourServicesSetting.settings && ourServicesSetting.settings.filter(item => item?.component_name === 'headline')[0].content_text
    const ourServicesTitle = ourServicesSetting && ourServicesSetting.settings && ourServicesSetting.settings.filter(item => item?.component_name === 'title')[0].content_text
    const actionButtonText = ourServicesSetting && ourServicesSetting.settings && ourServicesSetting.settings.filter(item => item?.component_name === 'action-button-text')[0].content_text
    const detailsButtonText = ourServicesSetting && ourServicesSetting.settings && ourServicesSetting.settings.filter(item => item?.component_name === 'details-button-text')[0].content_text

    useEffect(() => {
        getAllServices()
    }, [getAllServices])

    return (
        <div className='bg-app-cream flex-1'>
            <div className='app-main-container'>
                <p className='font-serif text-xl sm:text-3xl mb-6 sm:mb-8 font-bold app-animation-translate-start'>
                    {ourServicesHeadline && ourServicesHeadline !== '' ? ourServicesHeadline : 'Book your spa experiences'}
                </p>
                <div className='space-y-4'>
                    <p className='text-base sm:text-2xl app-animation-translate-start'>
                        {ourServicesTitle && ourServicesTitle !== '' ? ourServicesTitle : 'Select treatments'}
                    </p>
                    {services?.map((treatment) => (
                        <ServiceCard
                            id={treatment?.id}
                            title={treatment?.title}
                            description={treatment?.description}
                            actionButtonText={actionButtonText}
                            detailsButtonText={detailsButtonText}
                        />
                    ))}
                </div>
            </div>
        </div>
    )
}

export default OurServices