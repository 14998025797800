import React from 'react'
import { useSelector } from 'react-redux'
import { useFormik } from "formik"
import * as Yup from "yup"
import ReCAPTCHA from "react-google-recaptcha";
import useFetch from 'utils/hooks/useFetch';

import TextAreaInput from 'modules/booking-module-client/components/input/TextAreaInput'
import TextInput from 'modules/booking-module-client/components/input/TextInput'

import BuildingIcon from 'assets/icons/general/BuildingIcon'
import EmailIcon from 'assets/icons/general/EmailIcon'
import TelephoneIcon from 'assets/icons/general/TelephoneIcon'
import SelectInput from 'modules/booking-module-client/components/input/SelectInput'
import NumberInput from 'modules/booking-module-client/components/input/NumberInput'

import { countryPhoneCodes } from 'utils/constants/index'
import { SEND_CONTACT_FORM } from 'api';

function ContactUs() {
    const innerWidth = window.innerWidth
    const contactUsSetting = useSelector((state) => state.contactUsSetting)
    const { fetch: sendContactForm } = useFetch(SEND_CONTACT_FORM)

    const headlineContactus = contactUsSetting && contactUsSetting.settings && contactUsSetting.settings.filter(item => item?.component_name === 'headline')[0].content_text
    const titleContactUs = contactUsSetting && contactUsSetting.settings && contactUsSetting.settings.filter(item => item?.component_name === 'title')[0].content_text
    const contactNumber = contactUsSetting && contactUsSetting.settings && contactUsSetting.settings.filter(item => item?.component_name === 'contact-number')[0].content_text
    const contactAddress = contactUsSetting && contactUsSetting.settings && contactUsSetting.settings.filter(item => item?.component_name === 'contact-address')[0].content_text
    const contactEmail = contactUsSetting && contactUsSetting.settings && contactUsSetting.settings.filter(item => item?.component_name === 'contact-email')[0].content_text

    const formik = useFormik({
        initialValues: {
            email_address: '',
            full_name: '',
            phone_number: '',
            notes: '',
            country_phone_code: '',
            is_captcha_checked: false,
        },
        validationSchema: Yup.object().shape({
            email_address: Yup.string().required('Please input your email address').email('Invalid email format'),
            full_name: Yup.string().required('Please input your full name'),
            phone_number: Yup.string().required('Please input your phone number'),
            notes: Yup.string().required('Please input your notes'),
            is_captcha_checked: Yup.boolean(true).required('Please fill this requirement')
        }),
        validateOnBlur: false,
        validateOnChange: false,
        onSubmit: async (values, { setSubmitting }) => {
            const newContactFormSubmitted = await sendContactForm({
                data: {
                    full_name: values.full_name,
                    email_address: values.email_address,
                    phone_number: `${values.country_phone_code}${values.phone_number}`,
                    message: values.notes
                }
            })
            if (newContactFormSubmitted.success) {
                window.location.reload()
            }
        },
    });

    const onChangeCaptcha = (value) => {
        if (value) {
            formik.setValues((values) => ({
                ...values,
                is_captcha_checked: true
            }), false)
        } else {
            formik.setValues((values) => ({
                ...values,
                is_captcha_checked: false
            }), false)
        }
    };

    return (
        <section className='bg-cream flex-1'>
            <div className='app-main-container grid grid-cols-1 sm:grid-cols-2 gap-12'>
                <div className='space-y-8 sm:space-y-12'>
                    <div className='space-y-4'>
                        <p className='text-3xl sm:text-5xl text-app-green font-serif font-bold app-animation-translate-start'>
                            {headlineContactus && headlineContactus !== '' ? headlineContactus : 'Our story'}
                        </p>
                        <p className='text-lg sm:text-xl font-semibold app-animation-translate-start'>
                            {titleContactUs && titleContactUs !== '' ? titleContactUs : 'Our team would love to hear from you'}
                        </p>
                    </div>

                    <div className='space-y-4 sm:space-y-6'>
                        <div className='flex gap-2 sm:gap-4 app-animation-translate-start'>
                            <div>
                                <BuildingIcon />
                            </div>
                            <div className='space-y-1'>
                                <p className='text-sm sm:text-base'>
                                    {contactAddress && contactAddress !== '' ? contactAddress : 'Madison Avenue Building Tras Street, Block 201-203'}
                                </p>
                            </div>
                        </div>

                        <div className='flex gap-2 sm:gap-4 app-animation-translate-start'>
                            <div>
                                <TelephoneIcon />
                            </div>
                            <div>
                                <p className='text-sm sm:text-base'>
                                    {contactNumber && contactNumber !== '' ? contactNumber : '+628190000000'}
                                </p>
                            </div>
                        </div>

                        <div className='flex gap-2 sm:gap-4 app-animation-translate-start'>
                            <div>
                                <EmailIcon />
                            </div>
                            <div>
                                <p className='text-sm sm:text-base'>
                                    {contactEmail && contactEmail !== '' ? contactEmail : 'cremecoral@mail.com'}
                                </p>
                            </div>
                        </div>
                    </div>
                </div>
                <div className='space-y-3 sm:space-y-6 app-animation-translate-start'>
                    <TextInput
                        name="full_name"
                        label="Full name"
                        required
                        placeholder="Fill in your full name"
                        value={formik.values.full_name}
                        onChange={formik.handleChange}
                        error={formik.errors.full_name}
                    />
                    <TextInput
                        name="email_address"
                        label="Email address"
                        required
                        placeholder="Fill in your email address"
                        value={formik.values.email_address}
                        onChange={formik.handleChange}
                        error={formik.errors.email_address}
                    />
                    <div className='space-y-2'>
                        <label for="phone_number" className='font-bold flex text-sm sm:text-base'>
                            <p>Phone number</p>
                            {true && (
                                <span className='text-app-brown'>*</span>
                            )}
                        </label>

                        <div className='grid grid-cols-3 gap-4 items-center'>
                            <SelectInput
                                name="country_phone_code"
                                placeholder="Choose country"
                                options={countryPhoneCodes.map(code => ({ id: code.code, text: `${code.name}(${code.code})` }))}
                                value={formik.values.country_phone_code}
                                onChange={formik.handleChange}
                            />
                            <NumberInput
                                className="col-span-2"
                                name="phone_number"
                                placeholder="Fill in your phone number"
                                value={formik.values.phone_number}
                                onChange={formik.handleChange}
                            />
                        </div>

                        {formik.errors.phone_number && (
                            <p className='text-xs text-app-brown'>{formik.errors.phone_number}</p>
                        )}
                    </div>

                    <TextAreaInput
                        name="notes"
                        label="Notes"
                        rows={5}
                        placeholder="Enter your notes or other information"
                        value={formik.values.notes}
                        onChange={formik.handleChange}
                        error={formik.errors.notes}
                    />

                    <div style={{ transform: innerWidth < 768 ? "scale(0.8)" : "scale(1)", transformOrigin: "0 0" }}>
                        <ReCAPTCHA
                            sitekey='6Le7pvUaAAAAAAiPYg6vfzaD7kFju2jFBEYuvvaK' // eCommerce
                            // sitekey="6LcNGREqAAAAAAp0UblfP-Uw2hEQZW3EH9GlCr8H"
                            // sitekey="6LeIxAcTAAAAAJcZVRqyHh71UMIEGNQ_MXjiZKhI" // Development
                            onChange={onChangeCaptcha}
                        />
                    </div>

                    <button
                        onClick={formik.handleSubmit}
                        className='text-sm sm:text-base bg-black disabled:opacity-25 text-white w-full rounded-xl p-2 sm:p-3'
                        disabled={
                            !formik.values.email_address ||
                            formik.values.email_address === '' ||
                            !formik.values.full_name ||
                            formik.values.full_name === '' ||
                            !formik.values.phone_number ||
                            formik.values.phone_number === '' ||
                            !formik.values.notes ||
                            formik.values.notes === '' ||
                            !formik.values.is_captcha_checked
                        }
                    >
                        Send message
                    </button>
                </div>
            </div>
        </section>
    )
}

export default ContactUs