import React from 'react';
import { useSelector } from 'react-redux'
import mainBanner from 'assets/images/main-banner.png'
import item1 from 'assets/images/item.png'
import Newsletter from 'components/newsletter/Newsletter';

function Home() {
    const homeSetting = useSelector((state) => state.homeSetting)

    const homeMainBanner = homeSetting && homeSetting.settings && homeSetting.settings.filter(item => item?.component_name === 'main-banner')[0].content_file_url
    const highlightTitle1 = homeSetting && homeSetting.settings && homeSetting.settings.filter(item => item?.component_name === 'highlight-title-1')[0].content_text
    const highlightDesc1 = homeSetting && homeSetting.settings && homeSetting.settings.filter(item => item?.component_name === 'highlight-description-1')[0].content_text
    const highlightLink1 = homeSetting && homeSetting.settings && homeSetting.settings.filter(item => item?.component_name === 'highlight-link-1')[0].content_text
    const highlightBanner1 = homeSetting && homeSetting.settings && homeSetting.settings.filter(item => item?.component_name === 'highlight-banner-1')[0].content_file_url
    const highlightTitle2 = homeSetting && homeSetting.settings && homeSetting.settings.filter(item => item?.component_name === 'highlight-title-2')[0].content_text
    const highlightDesc2 = homeSetting && homeSetting.settings && homeSetting.settings.filter(item => item?.component_name === 'highlight-description-2')[0].content_text
    const highlightLink2 = homeSetting && homeSetting.settings && homeSetting.settings.filter(item => item?.component_name === 'highlight-link-2')[0].content_text
    const highlightBanner2 = homeSetting && homeSetting.settings && homeSetting.settings.filter(item => item?.component_name === 'highlight-banner-2')[0].content_file_url

    return (
        <div className='flex-1'>
            <section>
                <img src={homeMainBanner && homeMainBanner !== '' ? homeMainBanner : mainBanner} alt="main-banner" className='max-h-screen w-full object-cover' />
            </section>

            <section className='grid grid-cols-1 sm:grid-cols-2'>
                <div className='order-1 sm:order-none' >
                    <a href={highlightLink1 && highlightLink1 !== '' ? highlightLink1 : "/"} target='_blank' rel="noopener noreferrer">
                        <img
                            src={highlightBanner1 && highlightBanner1 !== '' ? highlightBanner1 : item1}
                            alt="item-1"
                            className='w-full object-cover max-h-[80vh] app-animation-translate-start'
                        />
                    </a>
                </div>
                <div className='bg-app-cream py-8 sm:py-12 px-6 sm:px-16 flex items-center'>
                    <div className='max-w-md space-y-8'>
                        <p className='text-2xl sm:text-4xl text-app-green font-serif font-bold app-animation-translate-start'>
                            {
                                highlightTitle1 && highlightTitle1 !== '' ? highlightTitle1
                                    : 'Natural and aromatic home series'
                            }
                        </p>
                        <p className='text-sm sm:text-base app-animation-translate-start'>
                            {
                                highlightDesc1 && highlightDesc1 !== '' ? highlightDesc1
                                    : 'Lorem ipsum dolor sit amet consectetur. Netus auctor sit dictum nec. Ut at nulla ornare facilisi consectetur. Commodo felis sed vestibulum et. Arcu nibh vulputate odio arcu et. Metus nisi aliquam enim justo. Platea blandit at diam montes purus enim pellentesque quis sagittis. Elit sed tellus bibendum lectus. Risus enim pretium ut convallis. Euismod tempus vitae proin duis.'
                            }
                        </p>
                    </div>
                </div>
            </section>

            <section className='grid grid-cols-1 sm:grid-cols-2'>
                <div className='bg-app-light-grey  py-8 sm:py-12 px-6 sm:px-16 flex items-center justify-end'>
                    <div className='max-w-md space-y-8'>
                        <p className='text-2xl sm:text-4xl text-app-green font-serif font-bold app-animation-translate-start'>
                            {
                                highlightTitle2 && highlightTitle2 !== '' ? highlightTitle2
                                    : 'Natural and aromatic home series'
                            }
                        </p>
                        <p className='text-sm sm:text-base app-animation-translate-start'>
                            {
                                highlightDesc2 && highlightDesc2 !== '' ? highlightDesc2
                                    : 'Lorem ipsum dolor sit amet consectetur. Netus auctor sit dictum nec. Ut at nulla ornare facilisi consectetur. Commodo felis sed vestibulum et. Arcu nibh vulputate odio arcu et. Metus nisi aliquam enim justo. Platea blandit at diam montes purus enim pellentesque quis sagittis. Elit sed tellus bibendum lectus. Risus enim pretium ut convallis. Euismod tempus vitae proin duis.'
                            }
                        </p>
                    </div>
                </div>
                <div className=''>
                    <a href={highlightLink2 && highlightLink2 !== '' ? highlightLink2 : "/"} target='_blank' rel="noopener noreferrer">
                        <img
                            src={highlightBanner2 && highlightBanner2 !== '' ? highlightBanner2 : item1}
                            alt="item-1"
                            className='w-full object-cover max-h-[80vh] app-animation-translate-start'
                        />
                    </a>
                </div>
            </section>

            <Newsletter />
        </div>
    )
}

export default Home