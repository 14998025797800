import React from 'react'
import { useSelector } from 'react-redux'

import NaturalIcon from 'assets/icons/general/NaturalIcon'
import ShampooIcon from 'assets/icons/general/ShampooIcon'
import MeditationIcon from 'assets/icons/general/MeditationIcon'

import item1 from 'assets/images/item.png'
import beach from 'assets/images/beach.png'
import Newsletter from 'components/newsletter/Newsletter'

function About() {
    const aboutUsSetting = useSelector((state) => state.aboutUsSetting)

    const aboutUsMainBanner = aboutUsSetting && aboutUsSetting.settings && aboutUsSetting.settings.filter(item => item?.component_name === 'main-banner')[0].content_file_url
    const topHighlightHeadline = aboutUsSetting && aboutUsSetting.settings && aboutUsSetting.settings.filter(item => item?.component_name === 'top-highlight-headline')[0].content_text
    const topHighlightTitle = aboutUsSetting && aboutUsSetting.settings && aboutUsSetting.settings.filter(item => item?.component_name === 'top-highlight-title')[0].content_text
    const topHighlightDesc = aboutUsSetting && aboutUsSetting.settings && aboutUsSetting.settings.filter(item => item?.component_name === 'top-highlight-description')[0].content_text
    const bottomHighlightDesc = aboutUsSetting && aboutUsSetting.settings && aboutUsSetting.settings.filter(item => item?.component_name === 'bottom-highlight-description')[0].content_text
    const bottomHighlightBanner = aboutUsSetting && aboutUsSetting.settings && aboutUsSetting.settings.filter(item => item?.component_name === 'bottom-highlight-banner')[0].content_file_url
    const cardHeadline1 = aboutUsSetting && aboutUsSetting.settings && aboutUsSetting.settings.filter(item => item?.component_name === 'card-headline-1')[0].content_text
    const cardDesc1 = aboutUsSetting && aboutUsSetting.settings && aboutUsSetting.settings.filter(item => item?.component_name === 'card-description-1')[0].content_text
    const cardImage1 = aboutUsSetting && aboutUsSetting.settings && aboutUsSetting.settings.filter(item => item?.component_name === 'card-image-1')[0].content_file_url
    const cardHeadline2 = aboutUsSetting && aboutUsSetting.settings && aboutUsSetting.settings.filter(item => item?.component_name === 'card-headline-2')[0].content_text
    const cardDesc2 = aboutUsSetting && aboutUsSetting.settings && aboutUsSetting.settings.filter(item => item?.component_name === 'card-description-2')[0].content_text
    const cardImage2 = aboutUsSetting && aboutUsSetting.settings && aboutUsSetting.settings.filter(item => item?.component_name === 'card-image-2')[0].content_file_url
    const cardHeadline3 = aboutUsSetting && aboutUsSetting.settings && aboutUsSetting.settings.filter(item => item?.component_name === 'card-headline-3')[0].content_text
    const cardDesc3 = aboutUsSetting && aboutUsSetting.settings && aboutUsSetting.settings.filter(item => item?.component_name === 'card-description-3')[0].content_text
    const cardImage3 = aboutUsSetting && aboutUsSetting.settings && aboutUsSetting.settings.filter(item => item?.component_name === 'card-image-3')[0].content_file_url

    return (
        <div className='flex-1'>
            <section>
                <img className='w-full max-h-[45vh] object-cover' src={aboutUsMainBanner && aboutUsMainBanner !== '' ? aboutUsMainBanner : item1} alt="item-1" />
            </section>
            <section className='bg-app-cream'>
                <div className='app-main-container grid grid-cols-1 sm:grid-cols-2 gap-8'>
                    <div className='space-y-4 sm:space-y-8'>
                        <p className='text-2xl sm:text-4xl text-app-green font-serif font-bold app-animation-translate-start'>{topHighlightHeadline && topHighlightHeadline !== '' ? topHighlightHeadline : 'Our story'}</p>
                        <p className='text-lg sm:text-2xl font-semibold sm:w-96 app-animation-translate-start'>{topHighlightTitle && topHighlightTitle !== '' ? topHighlightTitle : 'Unleash your natural beauty from inside out.'}</p>
                    </div>
                    <div className='space-y-8 app-animation-translate-start'>
                        <p className='text-sm sm:text-base'>
                            {
                                topHighlightDesc && topHighlightDesc !== '' ? topHighlightDesc
                                    : 'Lorem ipsum dolor sit amet consectetur. Netus auctor sit dictum nec. Ut at nulla ornare facilisi consectetur. Commodo felis sed vestibulum et. Arcu nibh vulputate odio arcu et. Metus nisi aliquam enim justo. Platea blandit at diam montes purus enim pellentesque quis sagittis. Elit sed tellus bibendum lectus. Risus enim pretium ut convallis. Euismod tempus vitae proin duis.'
                            }
                        </p>
                    </div>
                </div>
            </section>

            <section>
                <div className='app-main-container grid grid-cols-1 sm:grid-cols-3 gap-4 sm:gap-8'>
                    <div className='bg-app-yellow-green rounded-2xl px-8 py-12 flex flex-col items-center gap-4 sm:gap-8 text-center app-animation-translate-start'>
                        {cardImage1 && cardImage1 !== '' ? (
                            <img
                                className='w-full max-h-[45vh] object-cover'
                                src={cardImage1}
                                alt="card-image-1"
                            />
                        ) : (
                            <NaturalIcon />
                        )}
                        <p className='text-lg sm:text-xl font-bold'>{cardHeadline1 && cardHeadline1 !== '' ? cardHeadline1 : "Our products are naturals"}</p>
                        <p className='text-sm sm:text-base'>
                            {
                                cardDesc1 && cardDesc1 !== '' ? cardDesc1
                                    : 'Lorem ipsum dolor sit amet consectetur. Netus auctor sit dictum nec. Ut at nulla ornare facilisi consectetur. Commodo felis sed vestibulum et.'
                            }
                        </p>
                    </div>
                    <div className='bg-app-yellow-green rounded-2xl px-8 py-12 flex flex-col items-center gap-4 sm:gap-8 text-center app-animation-translate-start'>
                        {cardImage2 && cardImage2 !== '' ? (
                            <img
                                className='w-full max-h-[45vh] object-cover'
                                src={cardImage2}
                                alt="card-image-1"
                            />
                        ) : (
                            <ShampooIcon />
                        )}
                        <p className='text-lg sm:text-xl font-bold'>{cardHeadline2 && cardHeadline2 !== '' ? cardHeadline2 : 'Committed to increase your well being'}</p>
                        <p className='text-sm sm:text-base'>
                            {
                                cardDesc2 && cardDesc2 !== '' ? cardDesc2
                                    : 'Lorem ipsum dolor sit amet consectetur. Netus auctor sit dictum nec. Ut at nulla ornare facilisi consectetur. Commodo felis sed vestibulum et.'
                            }
                        </p>
                    </div>
                    <div className='bg-app-yellow-green rounded-2xl px-8 py-12 flex flex-col items-center gap-4 sm:gap-8 text-center app-animation-translate-start'>
                        {cardImage3 && cardImage3 !== '' ? (
                            <img
                                className='w-full max-h-[45vh] object-cover'
                                src={cardImage3}
                                alt="card-image-1"
                            />
                        ) : (
                            <MeditationIcon />
                        )}
                        <p className='text-lg sm:text-xl font-bold'>{cardHeadline3 && cardHeadline3 !== '' ? cardHeadline3 : 'Surrounded by nature'}</p>
                        <p className='text-sm sm:text-base'>
                            {
                                cardDesc3 && cardDesc3 !== '' ? cardDesc3
                                    : 'Lorem ipsum dolor sit amet consectetur. Netus auctor sit dictum nec. Ut at nulla ornare facilisi consectetur. Commodo felis sed vestibulum et.'
                            }
                        </p>
                    </div>
                </div>
            </section>

            <section className='bg-app-light-grey'>
                <div className='app-main-container py-12 flex flex-col-reverse sm:flex-row items-center gap-8 sm:gap-16'>
                    <div className='sm:w-2/5'>
                        <p className='text-base sm:text-xl text-center sm:text-left app-animation-translate-start'>
                            {
                                bottomHighlightDesc && bottomHighlightDesc !== '' ? bottomHighlightDesc
                                    : 'Lorem ipsum dolor sit amet consectetur. Netus auctor sit dictum nec. Ut at nulla ornare facilisi consectetur. Commodo felis sed vestibulum et. Arcu nibh vulputate odio arcu et. Metus nisi aliquam enim justo.'
                            }
                        </p>
                    </div>
                    <div className='sm:w-3/5 app-animation-scale-start'>
                        <img src={bottomHighlightBanner && bottomHighlightBanner !== '' ? bottomHighlightBanner : beach} alt="beach" />
                    </div>
                </div>
            </section>

            <Newsletter />
        </div>
    )
}

export default About