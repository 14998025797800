import React from 'react'

function MeditationIcon({
    className = "h-12 sm:h-16 w-12 sm:w-16 stroke-black"
}) {
    return (
        <svg className={className} viewBox="0 0 78 78" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M49.284 51.0156L52.716 58.3956C52.716 58.3956 68.144 61.6716 68.144 69.4636C68.144 74.9996 61.28 74.9996 61.28 74.9996H43L34 69.9996" stroke-width="6" stroke-linecap="round" stroke-linejoin="round" />
            <path d="M28.716 51.0156L25.288 58.3956C25.288 58.3956 9.85596 61.6716 9.85596 69.4636C9.85596 74.9996 16.72 74.9996 16.72 74.9996H25L34 69.9996L45 62.9996" stroke-width="6" stroke-linecap="round" stroke-linejoin="round" />
            <path d="M3 54.704C3 54.704 11.572 52.86 16.716 51.016C21.856 25.184 37.28 27.028 39 27.028C40.716 27.028 56.144 25.184 61.284 51.016C66.428 52.856 75.0001 54.704 75.0001 54.704M39 19C41.1218 19 43.1566 18.1572 44.6569 16.6569C46.1572 15.1566 47 13.1217 47 11C47 8.87827 46.1572 6.84344 44.6569 5.34315C43.1566 3.84285 41.1218 3 39 3C36.8783 3 34.8435 3.84285 33.3432 5.34315C31.8429 6.84344 31 8.87827 31 11C31 13.1217 31.8429 15.1566 33.3432 16.6569C34.8435 18.1572 36.8783 19 39 19Z" stroke-width="6" stroke-linecap="round" stroke-linejoin="round" />
        </svg>
    )
}

export default MeditationIcon