import moment from 'moment'
import React from 'react'

function Summary({
  details,
  date,
  num_guests,
}) {
  return (
    <div className='space-y-4 border border-black rounded-xl px-4 py-4 bg-app-light-grey'>
      <div className='space-y-2'>
        <p className='uppercase text-xs sm:text-sm text-app-grey font-bold'>{details.label}</p>
        <p className='text-sm sm:text-base'>{details?.title}</p>
      </div>
      <div className='space-y-2'>
        <p className='uppercase text-xs sm:text-sm text-app-grey font-bold'>Date/Time</p>
        <p className='text-sm sm:text-base'>{moment(date).format('DD MMMM YYYY HH:mm')}</p>
      </div>
      <div className='space-y-2'>
        <p className='uppercase text-xs sm:text-sm text-app-grey font-bold'>Guest</p>
        <p className='text-sm sm:text-base'>{num_guests}&nbsp;{num_guests > 1 ? "guests" : "guest"}</p>
      </div>
    </div>
  )
}

export default Summary