import React from 'react'

function SearchIcon({
    className = "h-6 w-6 fill-black"
}) {
    return (
        <svg className={className} viewBox="0 0 25 24" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M17.4363 10.1538C17.4363 8.375 16.8041 6.85337 15.5397 5.58894C14.2752 4.32452 12.7536 3.69231 10.9748 3.69231C9.19592 3.69231 7.67429 4.32452 6.40987 5.58894C5.14544 6.85337 4.51323 8.375 4.51323 10.1538C4.51323 11.9327 5.14544 13.4543 6.40987 14.7188C7.67429 15.9832 9.19592 16.6154 10.9748 16.6154C12.7536 16.6154 14.2752 15.9832 15.5397 14.7188C16.8041 13.4543 17.4363 11.9327 17.4363 10.1538ZM24.8209 22.1538C24.8209 22.6538 24.6382 23.0865 24.2728 23.4519C23.9075 23.8173 23.4748 24 22.9748 24C22.4555 24 22.0228 23.8173 21.6767 23.4519L16.7296 18.5192C15.0084 19.7115 13.0902 20.3077 10.9748 20.3077C9.59977 20.3077 8.28487 20.0409 7.03006 19.5072C5.77525 18.9736 4.69352 18.2524 3.78487 17.3438C2.87621 16.4351 2.15506 15.3534 1.6214 14.0986C1.08775 12.8438 0.820923 11.5288 0.820923 10.1538C0.820923 8.77885 1.08775 7.46394 1.6214 6.20913C2.15506 4.95433 2.87621 3.8726 3.78487 2.96394C4.69352 2.05529 5.77525 1.33413 7.03006 0.800481C8.28487 0.266827 9.59977 0 10.9748 0C12.3498 0 13.6647 0.266827 14.9195 0.800481C16.1743 1.33413 17.256 2.05529 18.1647 2.96394C19.0733 3.8726 19.7945 4.95433 20.3281 6.20913C20.8618 7.46394 21.1286 8.77885 21.1286 10.1538C21.1286 12.2692 20.5325 14.1875 19.3402 15.9087L24.2873 20.8558C24.643 21.2115 24.8209 21.6442 24.8209 22.1538Z" />
        </svg>
    )
}

export default SearchIcon